import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../service/auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { adminTheme } from './theme';

import logo from '../assets/logo.png';
import profile from '../assets/artra-seed-logo.png';



const pageInfo = {
    "gallery": {
        "title": "Gallery",
        "route": "/gallery"
    },
    "art": {
        "title": "Art",
        "route": "/art"
    },
    "artist": {
        "title": "Artist",
        "route": "/artist"
    },
    // "banner": {
    //     "title": "Banner",
    //     "route": "/banner"
    // },
    // "story": {
    //     "title": "Story",
    //     "route": "/story"
    // },
    "soldout": {
        "title": "Soldout",
        "route": "/soldout"
    }, 
    "rakama": {
        "title": "User",
        "route": "/rakama"
    },
    "blog": {
        "title": "Blog",
        "route": "/blog"
    },
    "opportunity": {
        "title": "Opportunity",
        "route": "/opportunity"
    },
    "payment": {
        "title": "Payment",
        "route": "/payment"
    }
    // "dashboard": {
    //     "title": "Dashboard",
    //     "route": "/dashboard"
    // },
    // "membership": {
    //     "title": "Membership",
    //     "route": "/membership"
    // },
    // "point": {
    //     "title": "Point",
    //     "route": "/point"
    // }
}

const theme = createTheme();

export default function Nav() {
    
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const user = auth.user
    const admin = ['9uv97Sf3iTUWzhd3qVYGA0rkAqq2']
    // const superuser = ['W6vUkyoMzCTV6GUejXzk4G8imi43', 'BR8MTOryHuNv9vuRs2REwNdr80g2', 'GeoagYzHDqgzWZYMUw8g3pigTPo2', '9CEKTPi0JObkxhQfxD7X6LHlVXe2', 'j6KmShn5noa28kqiPLzNgyYgrMt1']
    const superuser = ['W6vUkyoMzCTV6GUejXzk4G8imi43', 'BR8MTOryHuNv9vuRs2REwNdr80g2', 'GeoagYzHDqgzWZYMUw8g3pigTPo2', '9CEKTPi0JObkxhQfxD7X6LHlVXe2','PRHVWGugNSgJmGH9Uj9krQH2BOI2', '5uqZNlgT7Hhl1aTahrUnTR74zyx1']
    
    
    const isSuperUser = (user) => {
        return user ? superuser.includes(user.uid) : false
    }

    const isAdmin = (user) => {
        return user ? admin.includes(user.uid) : false
    }
    // ['rakama', 'gallery', 'art','artist', 'soldout', ]
    const pages = (isSuperUser(user) ? ['rakama', 'blog', 'opportunity', 'payment' ] : [])

    const settings = ['Logout'];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (key) => {
        navigate(pageInfo[key].route);
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        
        auth.signout(() => {
            navigate("/login", {replace: true});
        });
        
        setAnchorElUser(null);
    };

    return (
        <ThemeProvider theme={adminTheme}>
            <AppBar position="static" color='secondary'>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                
                <Box sx={{ mr: 2, display: { xs: 'none', md: 'flex' }}}>
                    <Link to="/" ><img src={logo} width="70px"/></Link>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                            <Typography textAlign="center" color="#222">{pageInfo[page].title}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <Link to="/" ><img src={logo} width="70px"/></Link>
                </Box>
                
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                    <Button
                        key={page}
                        onClick={() => handleCloseNavMenu(page)}
                        sx={{ my: 2, color: '#fff', display: 'block' }}
                    >
                        {pageInfo[page].title}
                    </Button>
                    ))}
                </Box>
                {auth.user != null ?
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="" src={profile} />
                    </IconButton>
                    </Tooltip>
                    <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    >
                    {settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                    </Menu>
                </Box> : null }
                </Toolbar>
            </Container>
            </AppBar>
        </ThemeProvider>
    );
}