import * as React from 'react';
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { css } from '@emotion/css'
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { useContext, useState } from "react";
import { AuthContext } from '../service/auth';
import Api from "../service/api";
import { useAppDispatch, useAppSelector } from '../store/hook';
import Loader from './Loader';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';

const css_container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 90px 32px;
`

const css_header = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
`

function useAuth() {
    return useContext(AuthContext);
}
const api = new Api()

export default function Membership(props) {
    const user = useAuth().user;
    const [loading, setLoading] = useState(false)
    const [records, setRecords] = useState([])

    React.useEffect(()=>{
        const getRecords = async() => {
            try {
                const res = await api.getAllSalesRecords(user)
                if(res.code == 200) {
                    setRecords(res.data)
                    console.log(res.data.length)
                }
            } catch(e) {
                console.log(e)
            }
        }
        getRecords()
    },[])
    
    return <div>
         <div className={css_header}>    
                <h2 style={{display: "inline-block"}}>멤버십</h2>
                <Link to="/membership_add"><Button variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon /></Button></Link>
            </div>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}}>
            <TableHead>
                <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>uid</TableCell>
                    <TableCell>child</TableCell>
                    <TableCell>grade</TableCell>
                    <TableCell>지급</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {
                records.map((item, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{`${item.id}`}</TableCell>
                        <TableCell>{item.uid}</TableCell>
                        <TableCell>{item.child}</TableCell>
                        <TableCell>{item.grade}</TableCell>
                        <TableCell>{item.paid}</TableCell>
                        {/* <TableCell><Link to={`/artist_edit/${item.id}`}><Button variant="contained" color="secondary" style={{verticalAlign: "center", marginLeft: '50px'}}>편집</Button></Link></TableCell> */}
                    </TableRow>
                ))
            }
            </TableBody>
          </Table>
        </TableContainer>
        </div>
}