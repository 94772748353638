export default function numberToKor(num) {
    const val = num.toString()
    var numKor = new Array("", "일", "이", "삼", "사","오","육","칠","팔","구","십"); 
    
    var danKor = new Array("", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천"); 
    var result = ""; if(val && !isNaN(val)){ 
        for(var i=0; i < val.length; i++) { var str = ""; 
        var num = numKor[val.charAt(val.length - (i+1))]; 
        if(num != "") {
            str += num + danKor[i]; 
        }
        switch(i){ 
            case 4:str += "만";break; 
            case 8:str += "억";break; 
            case 12:str += "조";break;
         } 
         result = str + result; 
        } result = result + "원";
     } return result ; 
}