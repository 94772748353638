import * as React from 'react';
import { Autocomplete, Box, Button, Input, FormGroup, InputAdornment, MenuItem, Select, Stack, TextField, Checkbox, Radio, Table, TableBody, TableCell } from "@mui/material";
import { css } from '@emotion/css'
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { useContext, useState } from "react";
import { AuthContext } from '../service/auth';
import { useLocation, useNavigate, useParams } from 'react-router';
import Api from "../service/api";
import { useAppDispatch, useAppSelector } from '../store/hook';
import Loader from './Loader';
import QueryString from 'qs';

const css_container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 90px 32px;
`

function useAuth() {
    return useContext(AuthContext);
}
const api = new Api()

export default function MembershipAdd(props) {
    const user = useAuth().user;
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [users,setUsers] = useState([])
    // const { uuid, ugrade } = useParams()
    
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    console.log(queryData);
    const uuid = queryData.uuid
    const ugrade = queryData.ugrade
    const utx = queryData.utx

    const [selectedUser, setSelectedUser] = useState(null)
    const [grade, setGrade] = useState(ugrade ? ugrade : 0)
    const gradeTypes = [
        {id: 0, title: 'Novice'},
        {id: 1, title: 'Club'},
        {id: 2, title: 'Prestiege'}
    ]
    const salesAmounts = [
        0, 330000, 3300000
    ]
    const feeAmounts = [
        0, 99000, 990000
    ]
    const artraAmounts = [
        0, 300, 3000
    ]
    
    React.useEffect(()=>{
        const getUsers = async() => {
            try {
                const users = await api.getBizUsers(user)
                const hadParents = users.filter((user) => user.referrer != null)
                setUsers(hadParents)
                const selected = getDefaultUserByUid(uuid)
                setSelectedUser(selected)
            } catch(e) {
                console.log(e)
            }
        }
        getUsers()
    },[])

    const onChangeUser = (event, user) => {
        
        setSelectedUser(user)
        console.log(`${user.referrer} is parent address`)
    }   
    const handleGradeTypeSelected = (event) => {
        setGrade(event.target.value)
    }

    const getDefaultUserByUid = (uid) => {
        if (uid != null) {
            const userSelected = users.filter((user) => user.uid == uid)[0]
            console.log(`Selected: ${JSON.stringify(userSelected)}`)
            if (selectedUser == null) {
                setSelectedUser(userSelected)
            }
            return userSelected
        } else {
            return null
        }
    }

    const handleSubmit = async() => {
        setLoading(true)
        try {   
            const salesAmount = salesAmounts[grade]
            const feeAmount = feeAmounts[grade]
            const artraAmount = artraAmounts[grade]
            const res = await api.updateUserMembership(user, selectedUser.uid, selectedUser.referrer, grade, salesAmount, feeAmount, artraAmount)
            
            if(grade == 2) {
                const tx = utx == null ? '' : utx 
                const res2 = await api.addPoints(user,selectedUser.uid,100,tx)
                console.log(JSON.stringify(res2))
            }
            
            setLoading(false)
            navigate('/membership')
        } catch(e) {
            console.log(e)
            setLoading(false)
        }
    }
    
    return <Loader active={loading}>
        {/* <div className={css_container}> */}
        <div>
        <h1>멤버십 추가</h1>
        <h4>등록할 유저</h4>
        {users.length > 0 ?
        <Stack>
            <Autocomplete
                freeSolo={true}
                id='search-user'
                disableClearable={true}
                options={users}
                getOptionLabel={(option) =>  `[${option.grade}] ${option.alias} - ${option.uid}`}
                renderInput={ (params) => (
                    <TextField 
                        {...params}
                        label = "Search Input"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
                onChange={onChangeUser}
                defaultValue={getDefaultUserByUid(uuid)}
            />
        </Stack> : null}
        <h4>멤버십 등급</h4>
        <p>0: Novice, 1: Club, 2: Prestiege, 3: Leader</p>
        <Stack>
        <Select
            id="select-grade-type"
            value={grade}
            label="등급"
            onChange={handleGradeTypeSelected}
        >
            {
                gradeTypes.map((type) => { 
                    return <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>
                    })
            }
        </Select>
        </Stack>
        
        {selectedUser == null ? null : 
        <div>
            <li>{selectedUser.uid}</li>
            <li>{selectedUser.referrer ? 'Has parent' : 'Select User'}</li>
            <li>{grade}</li>
            <li>{utx}</li>
        </div> }


        <Button onClick={handleSubmit} variant='contained' component='label' style={{marginTop: '45px', width: '100%', height: '55px'}} disabled={selectedUser == null || selectedUser.referrer == null || grade == 0}>{`Update to ${gradeTypes[grade].title}`}</Button>

    </div>
    </Loader>
}