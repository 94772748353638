import React, { useContext, useEffect, useState } from "react"
import Api from "../service/api"
import { AuthContext } from "../service/auth";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Button, TextField } from "@mui/material";
import { Delete, Download, RefreshRounded } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function useAuth() {
    return React.useContext(AuthContext);
}

const api = new Api()
export default function Rakama() {
    const [menu, setMenu] = useState(0)
    const user = useAuth().user;
    const [histories, setHistories] = useState([])
    const userHeaders = [
        {field: 'id', headerName:'UID', width: '80'},
        {field: 'type', headerName:'타입', width: '80'},
        {field: 'name', headerName: "이름",align: 'center', headerAlign:'center', width: '200'},
        {field: 'phone', headerName: "연락처", width: '200',align: 'center', headerAlign:'center'},
        {field: 'email', headerName: "이메일", width: '200',align: 'center', headerAlign:'center'},
        {field: 'code', headerName: '가입코드', width: '100',align: 'center', headerAlign: 'center'},
        {field: 'timestamp', headerName: '가입일', width: '100',align: 'center', headerAlign: 'center', renderCell: (params)=>(
           <p>{(new Date(params.value)).toLocaleDateString()}</p>
        )},
        {field: 'referrer', headerName: "추천",align: 'center', headerAlign:'center'},
    ]

    const artHeaders = [
        {field: 'id', headerName:'ID', width: '80',},
        {field: 'artist', headerName:'Artist', width: '150',},
        {field: 'title', headerName:'Title', width: '150',},
        {field: 'image', headerName:'Image', width: '50',},
        {field: 'gallery', headerName:'Gallery', width: '200',},
        {field: 'state', headerName:'State', width: '80',},
        {field: 'price', headerName:'Price', width: '150',},
    ]

    const planHeader = [
        {field: 'id', headerName:'UID', width: '80'},
        {field: 'planid', headerName:'Plan', width: '80'},
        {field: 'productid', headerName:'Product', width: '150'},
        {field: 'state', headerName:'State', width: '150'},
        {field: 'amount', headerName:'Amount', width: '80'},
        {field: 'currency', headerName:'KRW', width: '60'},
        {field: 'started', headerName:'Start', width: '100', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
        {field: 'expired', headerName:'End', width: '100', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
        {field: 'cancelled', headerName:'Cancel', width: '100', renderCell: (params)=>(
            <p>{params.value > 0 ? (new Date(params.value)).toLocaleDateString() : ''}</p>
         )}
    ]

    const convHeader = [
        {field: 'id', headerName:'UID', width: '80'},
        {field: 'email', headerName: "이메일", width: '200',align: 'center', headerAlign:'center'},
        {field: 'code', headerName: '가입코드', width: '100',align: 'center', headerAlign: 'center'},
        {field: 'planid', headerName:'Plan', width: '80'},
        {field: 'productid', headerName:'Product', width: '150'},
        {field: 'state', headerName:'State', width: '150'},
        {field: 'amount', headerName:'Amount', width: '80', type: 'number'},
        {field: 'currency', headerName:'KRW', width: '60'},
        {field: 'timestamp', headerName: '가입일', width: '100',align: 'center', headerAlign: 'center', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
         {field: 'lastAccess', headerName: '접속일', width: '100',align: 'center', headerAlign: 'center', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
         {field: 'since', headerName: '가입한지', width: '100',align: 'center', headerAlign: 'center',type: 'number'},
        {field: 'started', headerName:'Start', width: '100', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
        {field: 'expired', headerName:'End', width: '100', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
        {field: 'cancelled', headerName:'Cancel', width: '100', renderCell: (params)=>(
            <p>{params.value > 0 ? (new Date(params.value)).toLocaleDateString() : ''}</p>
         )},
         {field: 'domain', headerName:'domain', width: '200'},
         {field: 'arts', headerName:'작품수', width: '60', type: 'number'},
         {field: 'publicarts', headerName:'공개', width: '60', type: 'number'},
    ]

    const [users,setUsers] = useState([])
    const [userRows, setUserRows] = useState([])
    const [arts, setArts] = useState([])
    const [inv, setInv] = useState([])
    const [plans, setPlans] = useState([])
    // const [usersMap, setUsersMap] = useState({})
    // const [artsMap, setArtsMap] = useState({})
    // const [domainsMap, setDomainsMap] = useState({})
    const [conv, setConv] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedUser, setSelectedUser] = useState([])
    const [passwd,setPasswd] = useState('')
    const [filterStartDate, setFilterStartDate] = useState(null)
    const [filterEndDate, setFilterEndDate] = useState(null)
    const testAccount = [
        "artra.gallery",
        "artspoon.io",
        "poongd@gmail.com",
        "rakama",
        "test.com"
    ]
    // async function getAllUsers() {
    //     const res = await api.getRKMUsers(user) 
    //     if(res.data) {
    //         if(res.data.code==200) {
    //             const users = res.data.data
    //             setUsers(users)
    //             const rows = users.map(itemToRow)
    //             setUserRows(rows)
    //         }
    //     }
    // }
    // async function getAllArts() {
    //     const res = await api.getRKMArts(user)
    //     if(res.data) {
    //         if(res.data.code == 200) {
    //             const arts = res.data.data
    //             const rows = arts.map(artToRow)
    //             setArts(rows)
    //         }
    //     }
    // }

    // async function getAllInventories() {
    //     const res = await api.getRKMInventories(user)
    //     if(res.data) {
    //         if(res.data.code == 200) {
    //             const arts = res.data.data
    //             const rows = arts.map(invToRow)
    //             setInv(rows)
    //         }
    //     }
    // }

    // async function getCurrentPlans() {
    //     const res = await api.getRKMCurrentPlan(user)
    //     if(res.data && res.data.code == 200) {
    //         const plans = res.data.data
    //         setPlans(plans.map(planToRow))
    //     }
    // }

    async function getAll() {
        console.log('getAllData')
        const res = await api.getRKMAll(user)
        const hisRes = await api.getPaymentHistories(user)
        console.log('getAllData Done')
        if(res.data && res.data.code == 200 && hisRes.data && hisRes.data.code == 200) {
            const data = res.data.data
            const users = data.users
            var usersMap = {}
            users.forEach((user)=>{
                usersMap[user.uid] = user
            })
        
            var domainsMap = {}
            const domains = data.domains
            domains.forEach((domain) => {
                domainsMap[domain.uid] = domain
            })
       
            const artsMap = {}
            data.arts.forEach((art) => {
                var arr = artsMap[art.gid] ?? []
                arr.push(art)
                artsMap[art.gid] = arr
            })
            // console.log('ArtsMap:' + JSON.stringify(artsMap))
            setUsers(users)
            setUserRows(users.map(itemToRow))
            setInv(data.arts.map(invToRow))
            const originConv = data.plans.map((plan) => dataToRow(plan,usersMap,artsMap, domainsMap))
                .filter((row) => excludeTestAccount(testAccount, row.email) == false)
            setConv(originConv)
            setPlans(originConv)

            const his = hisRes.data.data.sort((l,r) => {
                if(l.historyid < r.historyid) {
                    return 1
                } else if(l.historyid > r.historyid) {
                    return -1
                } else {
                    return 0
                }
            })
            setHistories(his)
        }
        setLoading(false)
    }

    function excludeTestAccount(data, email) {
        for (const word of data) {
            // 대상 텍스트에 단어가 포함되어 있는지 확인
            if (email.includes(word)) {
                // 포함되어 있으면 해당 단어를 반환하고 배열을 빠져나옴
                return true;
            }
        }
        return false
    }

    async function doDelUser(uid) {
        const res = await api.delRKMUser(user, uid)
        console.log(`Del Result [${uid}]: ${JSON.stringify(res)}`)
    }

    function deleteSelectedUser() {
        console.log('deleteSelectedUser: ' + JSON.stringify(selectedUser))
        if(passwd == 'Dkxmfk@@77') {
            selectedUser.forEach((delUser) => {
                console.log('Try Delete: ' + delUser)
                doDelUser(delUser)
            })
            
        }
    }

    async function loadData() {
        setUserRows([])
        setUsers([])
        setArts([])
        setInv([])
        setPlans([])
        getAll()
        // getAllUsers()
        // getAllArts()
        // getAllInventories()
        // getCurrentPlans()
    }

    useEffect(()=>{
        if(user) {
            loadData()
        }
    },[user])

    function itemToRow(user) {
        // console.log(JSON.stringify(user))
        // const referrer = users.filter((item) => item.uid == user.referrer)
        return {
            'id': user.uid,
            'type': user.type,
            'name': user.name,
            'phone': user.phone,
            'email': user.email,
            'code': user.code,
            'timestamp': user.timestamp,
            'referrer': user.referrer
        }
    }

    function artToRow(art) {
        // console.log(JSON.stringify(art))
        return {
            'id': art.aid,
            'image': art.images.length > 0 ? "Y" : "N",
            'artist': art.artistname,
            'title': art.title,
            'gallery': art.uid,
            'state': art.state,
            'price': art.price.toLocaleString() + ' 원'
        }
    }

    function invToRow(art) {
        // console.log('art: ' + JSON.stringify(art))
        return {
            'id': art.iid,
            'image': art.image ? "Y": "N",
            'artist': art.artistname,
            'title': art.title,
            'gallery': `[${art.gid}] ${art.gallery.name}`,
            'state': art.state,
            'price': art.price > 0 ? art.price.toLocaleString() + ' 원' : '미입력'
        }
    }

    function planToRow(plan) {
        return {
            'id': plan.uid,
            'planid': plan.planid,
            'productid': plan.productid,
            'state': plan.state,
            'amount': plan.amount,
            'currency': plan.currency,
            'started': plan.started,
            'expired': plan.expired,
            'cancelled': plan.cancelled
        }
    }
    // {field: 'id', headerName:'UID', width: '80'},
    // {field: 'email', headerName: "이메일", width: '200',align: 'center', headerAlign:'center'},
    // {field: 'code', headerName: '가입코드', width: '100',align: 'center', headerAlign: 'center'},
    // {field: 'planid', headerName:'Plan', width: '80'},
    // {field: 'productid', headerName:'Product', width: '150'},
    // {field: 'state', headerName:'State', width: '150'},
    // {field: 'amount', headerName:'Amount', width: '80'},
    // {field: 'currency', headerName:'KRW', width: '60'},
    // {field: 'timestamp', headerName: '가입일', width: '100',align: 'center', headerAlign: 'center', renderCell: (params)=>(
    //     <p>{(new Date(params.value)).toLocaleDateString()}</p>
    //  )},
    // {field: 'started', headerName:'Start', width: '100', renderCell: (params)=>(
    //     <p>{(new Date(params.value)).toLocaleDateString()}</p>
    //  )},
    // {field: 'expired', headerName:'End', width: '100', renderCell: (params)=>(
    //     <p>{(new Date(params.value)).toLocaleDateString()}</p>
    //  )},
    // {field: 'cancelled', headerName:'Cancel', width: '100', renderCell: (params)=>(
    //     <p>{params.value > 0 ? (new Date(params.value)).toLocaleDateString() : ''}</p>
    //  )},
    //  {field: 'domain', headerName:'domain', width: '60'},
    //  {field: '작품수', headerName:'artcount', width: '60'},
    //  {field: '비공개 작품수', headerName:'artcount', width: '60'},
    function dataToRow(plan, usersMap, artsMap, domainsMap) {
        const uid = plan.uid
        const user = usersMap[uid] ?? {"email": "탈퇴한유저", "code": ""}
        const arts = artsMap[uid] ?? []
        const publicArts = arts.filter((art) => art.accesslevel == 9)
        const domain = domainsMap[uid]
        const since = parseInt((Date.now() - user.timestamp) / (1000*3600*24))
        // console.log('now: ' + Date.now() + ' , timestamp: ' + user.timestamp)
        // console.log('Since: ' + since / 1000*3600*24)
        // console.log('domain: ' + JSON.stringify(domain))
        return {
            'id': plan.uid,
            'email': user.email,
            'code': user.code,
            'planid': plan.planid,
            'productid': plan.productid,
            'state': plan.state,
            'amount': plan.amount,
            'currency': plan.currency,
            'timestamp': user.timestamp,
            'lastAccess': user.lastAccess,
            'since': since,
            'started': plan.started,
            'expired': plan.expired,
            'cancelled': plan.cancelled,
            'domain': domain ? domain.name : "none",
            'arts': arts.length,
            'publicarts': publicArts.length
        }
    }

    function getEmailData(type) {
        //users
        const emailData = users
                            .filter(user=>user.type == type)
                            .map((user)=>{
                                return [user.gid, user.email, user.name, user.phone]
                            })
        return emailData
    }
    // {"uid":267,"email":"dilshansinghdilshansingh571@gmail.com","name":"Dilshan singh ","phone":"+91 7415113316","referrer":0,"type":7,"code":"LE24M26","gid":267,"level":1,"timestamp":1711855074590}
    function getAllData() {
        const data = users
        .map((user)=>{
            return [user.uid, user.email, user.name, user.type, user.code, user.phone, user.timestamp]
        })
        return data
    }

    function getExpiredUserByCurrency(currency) {
        const data = conv
                    .filter((conv) => (conv.state != "active" && conv.currency == currency))
                    .map((conv) => {
                        return [conv.uid, conv.email]
                    })
        return data
    }

    function onRowSelected(selected) {
        console.log('selected: ' + selected)
        setSelectedUser(selected)
    }

    function startDateChanged(newValue) {
        console.log('startDate: ' + newValue)
        setFilterStartDate(newValue)        
        filterDate(newValue, filterEndDate)
    }

    function endDateChanged(newValue) {
        
        console.log('endDate: ' + newValue)
        setFilterEndDate(newValue)
        filterDate(filterStartDate, newValue)
    }

    function filterDate(startDate, endDate) {
        console.log('start: ' + startDate + ', endDate: ' + endDate)
        if(startDate != null && endDate != null) {
            const newPlans = plans.filter((item) => { return item.timestamp > startDate && item.timestamp < endDate })
            setConv(newPlans)  
        } else if (startDate != null) {
            const newPlans = plans.filter((item) => { return item.timestamp > startDate })
            setConv(newPlans)  
        }
    }

    function filterDateItem(item,startDate, endDate) {
        if(startDate != null && endDate != null) {
            return item.started > startDate && item.started < endDate
        } else if(startDate != null) {
            return item.started > startDate
        } else {
            return true
        }
    }

    //결제한사람
    function getPaidUser() {
        return conv.filter((row) => row.amount > 0 && row.state == "active")
    }

    //Trial 에서 이탈
    function getPaymentFailedUser() {
        return conv.filter((row) => row.amount == 0 && row.state == "payment_fail")
    }

    //결제 하다가 안한사람
    function getPaymentCancelledUser() {
        return conv.filter((row) => row.amount > 0 && row.state == "payment_fail")
    }

    function getPaymentAmount(paidUser) {
        var krw = 0
        var usd = 0
        paidUser
            .forEach((row) => {
                if(row.currency == 'KRW') {
                    krw += row.amount
                } else if(row.currency == 'USD') {
                    usd += row.amount
                }
            })
        return `KRW: ${krw.toLocaleString()}, USD: ${usd.toLocaleString()}`
    }

    function resetDate() {
        setFilterStartDate(null)
        setFilterEndDate(null)
        filterDate(null, null)
    }

    function buildConvHeader() {

        const totalUser = conv.length
        const paidUser = getPaidUser()
        const trialUser = conv.filter(row=> row.amount > 0 && row.state == "active").length
        const trialExitUser = getPaymentFailedUser().length
        const cancelUser = getPaymentCancelledUser().length

        const paidHistories = histories.filter((row) => row.amounttotal > 0)
        const firstPaid = {}
        paidHistories.forEach((row) => {
            const { uid, started } = row;
            
            if (!firstPaid[uid] || firstPaid[uid].started > started) {
                firstPaid[uid] = row;
            }
        })
        const firstPaidUsers = Object.values(firstPaid)
        const newPaidUsers = firstPaidUsers.filter((row) => filterDateItem(row, filterStartDate, filterEndDate))


        console.log(111)
        console.log(`totalUser: ${totalUser},  paidUser: ${paidUser}, trialExitUser: ${trialExitUser}, cancelUser: ${cancelUser}, newPaid: ${newPaidUsers}`)
        return <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <DatePicker label="Start Date"
                            onChange={(newValue) => startDateChanged(newValue)}
                        />
                        <DatePicker label="End Date"
                        // value={value}
                            onChange={(newValue) => endDateChanged(newValue)}
                        />
                         <Button variant="contained" onClick={e=>resetDate()}>Reset</Button>
                    </div>
                </LocalizationProvider>
                <div>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <p>구독 전환율</p>
                        <p style={{color:'#666',fontSize:'12px',margin:'0px 8px'}}>(* 결제유저 / 무료기간을 제외한 유저)</p>
                        <b>{(newPaidUsers.length/(totalUser-trialUser)*100).toFixed(2)} %</b></div>
                    </div>
                    <p>
                        <b>총 유저(기간내): </b>
                        {totalUser}명,
                        <b> 결제 유저: </b>
                        {newPaidUsers.length} 명,
                        <b> Trial 유저: </b>
                        {trialUser} 명,
                        <b> 이탈 유저(Trial): </b>
                        {trialExitUser} 명,
                        <b> 이탈 유저(구독취소): </b>
                        {cancelUser} 명
                    </p>
                    <p>
                        <b>ARR: </b>
                        {getPaymentAmount(paidUser)}
                    </p>
        </div>
    }

    function resetFilter() {
        setConv(plans)
    }

    function buildTables(menu) {
        switch(menu) {
            case 0:
                return <div>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <h2 style={{marginRight:'12px'}}>Users ({userRows.length})</h2>
                        <div style={{display:'flex', alignItems:'center', marginRight:'12px'}}>
                            <CSVLink data={getEmailData(7)}>Artist CSV</CSVLink> 
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'12px'}}>
                            <CSVLink data={getEmailData(0)}>Gallery CSV</CSVLink>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <CSVLink data={getAllData()}>Full CSV</CSVLink>
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'12px'}}>
                            <CSVLink data={getExpiredUserByCurrency('USD')}>구독만료 유저(USD) CSV</CSVLink> 
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'12px'}}>
                            <CSVLink data={getExpiredUserByCurrency('KRW')}>구독만료 유저(KRW) CSV</CSVLink> 
                        </div>
                    </div>
                    <DataGrid
                    loading={loading}
                    columns={userHeaders}
                    rows={userRows}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // id: false,
                            }
                        }
                    }}
                    checkboxSelection={true}
                    disableRowSelectionOnClick
                    isRowSelectable={params => true}
                    slots={{
                        toolbar: GridToolbarFilterButton
                    }}
                    />
              </div>

            case 1:
                return <div>
                <h2>Inventories ({inv.length})</h2>
                <DataGrid
                loading={loading}
                columns={artHeaders}
                rows={inv}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // id: false,
                        }
                    }
                }}
                checkboxSelection={true}
                disableRowSelectionOnClick
                isRowSelectable={params => true}
                slots={{
                    toolbar: GridToolbarFilterButton
                }}
                />
                </div>
            // case 2:
            //     return <div>
            //         <div>
            //             <h2>Current Membership</h2>
            //             <div style={{display:'flex', alignItems:'center'}}>
            //                     <CSVLink data={getAllMembershipData()}>Full CSV</CSVLink>
            //             </div>
            //     </div>
            //     <DataGrid
            //     loading={loading}
            //     columns={planHeader}
            //     rows={plans}
            //     initialState={{
            //         columns: {
            //             columnVisibilityModel: {
            //                 // id: false,
            //             }
            //         }
            //     }}
            //     checkboxSelection={true}
            //     disableRowSelectionOnClick
            //     isRowSelectable={params => true}
            //     slots={{
            //         toolbar: GridToolbarFilterButton
            //     }}
            //     />
            //     </div>
            case 2:
                return <div>
                     <div>
                        <h2>Conversions</h2>
                        
                        {buildConvHeader()}
                        <div style={{display:'flex', alignItems:'center'}}>
                            <TextField type="password" value={passwd} onChange={e=>{setPasswd(e.target.value)}}/>
                            <button onClick={e=>deleteSelectedUser()}><Delete /></button>
                                {/* <CSVLink data={getAllMembershipData()}>Full CSV</CSVLink> */}
                        </div>
                </div>
                <DataGrid
                    loading={loading}
                    columns={convHeader}
                    rows={conv}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // id: false,
                            }
                        }
                    }}
                    rowSelectionModel={selectedUser}
                    onRowSelectionModelChange={(selected) => {onRowSelected(selected)}}
                    checkboxSelection={true}
                    disableRowSelectionOnClick
                    isRowSelectable={params => true}
                    slots={{
                        toolbar: GridToolbarFilterButton
                    }}
                    />
                </div>
            default:
                    return null
        }
    }

    return <div>
        <h1>Art Spoon</h1>
        <div>
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Button style={{color: menu==0 ? '#000' : '#999'}} onClick={e=>setMenu(0)}>Users</Button> | 
                <Button style={{color: menu==1 ? '#000' : '#999'}} onClick={e=>setMenu(1)}>Inventories</Button> |
                {/* <Button style={{color: menu==2 ? '#000' : '#999'}} onClick={e=>setMenu(2)}>Membership</Button> |  */}
                <Button style={{color: menu==2 ? '#000' : '#999'}} onClick={e=>setMenu(2)}>Conversions</Button>
                {/* <Button style={{color: menu==2 ? '#000' : '#999'}} onClick={e=>setMenu(4)}>Histories</Button> */}
            </div>
            <Button onClick={loadData}><RefreshRounded /></Button>
            </div>
            {buildTables(menu)}
        </div>
    </div>
}