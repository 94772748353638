import * as React from 'react';
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { css } from '@emotion/css'
import Editor from '../components/Editor';

const css_container = css`
  display: flex;
  flex-direction: colmn;
  justify-content: center;
  align-items: center;
`

export default function Story() {
    return (
        <ThemeProvider theme={adminTheme}>
        <div className={css_container}>
            <Editor />
        </div>
        </ThemeProvider>
    );
}