import * as React from 'react';
import { Autocomplete, Box, Button, Input, FormGroup, InputAdornment, MenuItem, Select, Stack, TextField, Checkbox, Radio, Table, TableBody, TableCell } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from '../service/auth';
import { useLocation, useNavigate, useParams } from 'react-router';
import Api from "../service/api";
import Loader from './Loader';
import QueryString from 'qs';

function useAuth() {
    return useContext(AuthContext);
}
const api = new Api()

export default function PointAdd() {
    
    const user = useAuth().user;
    const navigate = useNavigate()
    const location = useLocation()

    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    console.log(queryData);
    const uuid = queryData.uuid

    const [loading, setLoading] = useState(false)
    const [users,setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [point, setPoint] = useState(0)
    const [tx, setTx] = useState(null)

    React.useEffect(()=>{
        const getUsers = async() => {
            try {
                const users = await api.getBizUsers(user)
                
                setUsers(users)
                const selected = getDefaultUserByUid(uuid)
                setSelectedUser(selected)
            } catch(e) {
                console.log(e)
            }
        }
        getUsers()
    },[])
    
    const getDefaultUserByUid = (uid) => {
        if (uid != null) {
            const userSelected = users.filter((user) => user.uid == uid)[0]
            console.log(`Selected: ${JSON.stringify(userSelected)}`)
            if (selectedUser == null) {
                setSelectedUser(userSelected)
            }
            return userSelected
        } else {
            return null
        }
    }
    const onChangeUser = (event, user) => {
        setSelectedUser(user)
    }  

    const onChangePointAmount = (event) => {
        const value = event.target.value
        setPoint(value)
    }

    const onChangeTx = (event) => {
        const value = event.target.value
        setTx(value)
    }

    const handleSubmit = async() => {
        setLoading(true)
        await api.addPoints(user, selectedUser.uid, point,tx)
        setLoading(false)
    }

    
    return <Loader active={loading}>
        <h2>Point 충전소</h2>
        <h4>등록할 유저</h4>
        {users.length > 0 ?
        <Stack>
            <Autocomplete
                freeSolo={true}
                id='search-user'
                disableClearable={true}
                options={users}
                getOptionLabel={(option) =>  `[${option.grade}] ${option.alias} - ${option.uid}`}
                renderInput={ (params) => (
                    <TextField 
                        {...params}
                        label = "Search Input"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
                onChange={onChangeUser}
                defaultValue={getDefaultUserByUid(uuid)}
            />
        </Stack> : null}
        <h4>포인트 입력(단위: 백만원)</h4>
        <div><Input  fullWidth type="number" placeholder="100 Point" value={point} onWheel={ event => event.target.blur()} onChange={onChangePointAmount} endAdornment={<InputAdornment position="end">Point</InputAdornment>}/></div>
        <p>{point} 만원</p>
        <h4>Tx Hash 입력</h4>
        <div><Input fullWidth placeholder='0xabcd....' value={tx} onChange={onChangeTx}/></div>
        <Button onClick={handleSubmit} variant='contained' component='label' style={{marginTop: '45px', width: '100%', height: '55px'}} disabled={false}>충전하기</Button>
    </Loader>
}