const firebaseConfig = {
    apiKey: "AIzaSyB0-YRNgKFtGmk333fwkxsJNUGgsU73L7s",
    authDomain: "artra-admin.firebaseapp.com",
    projectId: "artra-admin",
    storageBucket: "artra-admin.appspot.com",
    messagingSenderId: "65781292033",
    appId: "1:65781292033:web:cd6ec0a10ab23d40853400"
  };

  
export default firebaseConfig;