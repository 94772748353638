import * as React from 'react';
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';

import { css } from '@emotion/css'
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';
import Api from '../service/api';

import { useAppDispatch, useAppSelector } from '../store/hook'
import { GalleryItem, updateGalleries } from '../store/app'
import { Box } from '@mui/system';

const css_container = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const css_header = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
`

const css_body = css`
    display: block;
    margin-top: 90px;
`

const css_copy_button = css`
    background: #fff;
    border: none;
    cursor: pointer;
`

const api = new Api()

export default function Gallery() {
   
    
    const [data, setData] = React.useState([])
    const dispatch = useAppDispatch()

    React.useEffect(async()=>{
        const response = await api.getGallery()
        // console.log(`gallery response: ${JSON.stringify(response)}`)
        const galleries = response.map((item) => {
            console.log('gallery: ' + JSON.stringify(item))
            return new GalleryItem(item['id'], item['name'], item['contact'], item['email'], item['klaytnAddress'], item['address'], item['description'], item['link'], item['license'], item['logo'], item['banner'], item['seo'])
        })
        dispatch(updateGalleries(galleries))
        setData(galleries)
    },[])


    return (
        <ThemeProvider theme={adminTheme}>
        <div className={css_container}>
            <div className={css_header}>
                <h2 style={{display: "inline-block"}}> 갤러리 등록 페이지 </h2>
                <Link to="/gallery_add"><Button variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon /></Button></Link>
            </div>
        </div>
        <div className={css_body}>
            {
                data.map((item, index) => {
                    return (
                        <div key={index}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <h4>{item.id}</h4><Box width={12} /><img src={item.logo} height={40}/><Box width={30} /><h4>{item.name}</h4>
                                <Link to={`/gallery_edit/${item.id}`}><Button variant="contained" color="secondary" style={{verticalAlign: "center", marginLeft: '50px'}}>편집</Button></Link>
                            </div>
                            <p>{item.contact}</p>
                            <p>{item.email}</p>
                            <p>{item.klaytnAddress}</p>
                            <p>{item.description}</p>
                            <img src={item.banner} width={300}/>
                            
                            

                            <hr />
                        </div>
                    )
                })
            }
                {/* <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>이름</TableCell>
                                <TableCell>연락처</TableCell>
                                <TableCell>이메일</TableCell>
                                <TableCell>주소</TableCell>
                                <TableCell>Klaytn</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            data.map((item, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.contact}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.address}</TableCell>
                                    <TableCell>{item.klaytn_address} <button className={css_copy_button}><CopyIcon /></button> </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer> */}
            </div>
        </ThemeProvider>
    );
}