import { useContext, useEffect } from "react"
import Api from "../service/api"

import { useAppDispatch } from '../store/hook'
import { UserItem, updateUsers } from '../store/app'
import { AuthContext } from "../service/auth";

function useAuth() {
    return useContext(AuthContext);
}

const api = new Api()

export default function Load() {
    const dispatch = useAppDispatch()
    const user = useAuth().user
    useEffect(async () => {
        const res = await api.getUsers(user)
        if(res.data && res.data.code == 200) {
            const userJSON = res.data.data
            console.log(JSON.stringify(userJSON))
            const users = userJSON.map((user) => {
                return new UserItem(user['uid'], user['alias'], user['artist'], user['klaytnAddress'])
            })
            dispatch(updateUsers(users))
            console.log(`users: ${users}`)
        }
    },[user])

    return (<></>)
}