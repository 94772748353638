import * as React from 'react';
import { Autocomplete, Box, Button, Input, FormGroup, InputAdornment, MenuItem, Select, Stack, TextField, Checkbox, Radio, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from "@mui/material";
import { css } from '@emotion/css'
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { useContext, useState } from "react";
import { AuthContext } from '../service/auth';
import { useNavigate, useParams } from 'react-router';
import Api from "../service/api";
import { useAppDispatch, useAppSelector } from '../store/hook';
import Loader from './Loader';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutlined } from '@mui/icons-material';


const css_container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
function useAuth() {
    return useContext(AuthContext);
}

function useForceUpdate(){
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1); 
}

const api = new Api()

export default function ArtistAdd() {
    const user = useAuth().user;
    const [loading, setLoading] = useState(false)
    const [alias, setAlias] = useState('')
    const [aliasEn, setAliasEn] = useState('')
    const [birth, setBirth] = useState('')
    const [instagram, setInstagram] = useState('')
    const [description,setDescription] = useState('')
    const [klaytnAddress, setKlaytnAddress] = useState('')
    const [profile, setProfile] = useState(null)
    const [profileBase64, setProfileBase64] = useState(null)
    const [cv, setCv] = useState([])

    const { id } = useParams()
    const navigate = useNavigate()

    React.useEffect(async()=>{
        if(id) {
            console.log(id)
            const res = await api.getArtist(id)
            if(res.code == 200) {
                const data = res.data
                initFromJSON(data)
            }
        }
    },[])

    const initFromJSON = (json) => {
        console.log('Init from json: ' + JSON.stringify(json))
        setAlias(json.alias)
        setAliasEn(json.aliasEn)
        setBirth(json.birth)
        setInstagram(json.instagram)
        setDescription(json.introText)
        setKlaytnAddress(json.klaytnAddress)
        const cvJson = JSON.parse(json.cvText)
        setCv(cvJson.cv)
        forceUpdate()
    }

    const forceUpdate = useForceUpdate();

    const uid = () => {
        const id = Date.now().toString(36) + Math.random().toString(36).substr(2);
        console.log('uid: ' + id)
        return id
    }

    const onAddCV = async(index) => {
        console.log('insert at: ' + index)
        const newCV = cv.slice()
        newCV.splice(index,0,{"uid": uid(), "title": "", "contents": []})
        setCv([])
        await sleep(1)
        setCv(newCV)
    }

    const addContent = (index) => {
        const newCV = cv
        newCV[index].contents.push({"uid": uid(), "date": "", "text": "", "link": ""})
        setCv(newCV)
        forceUpdate()
    }

    const onProfileUploaded = (e) => {
        const files = e.target.files
        if (files && files.length > 0 ) {
            const data = files[0]
            setProfile(data)
            new Promise((resolve,reject) => {
                    const reader = new FileReader()
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result)
                    })
                    reader.addEventListener('error', reject)
                    reader.readAsDataURL(data)
            })
            .then(base64 => {
                setProfileBase64(base64)
            }, error => {
                console.log(error)
            })
        }
    }

    const deleteProfile = () => {
        setProfile(null)
        setProfileBase64(null)
    }

    function sleep(ms) {
        return new Promise((r) => setTimeout(r, ms));
      }

    const deleteCV = async(item) => {
        const newCv = cv.filter((i) => i.uid != item.uid)
        setCv([])
        await sleep(1)
        setCv(newCv)
    }

    const deleteContent = async(index, content) => {
        const newCV = cv.slice()
        newCV[index].contents = newCV[index].contents.filter((item) => item.uid != content.uid)
        setCv([])
        await sleep(1)
        setCv(newCV)
    }


    const handleSubmit = async() => {
        setLoading(true)
        const form = new FormData()
        form.append('alias', alias)
        form.append('aliasEn', aliasEn)
        form.append('birth', birth)
        form.append('instagram', instagram)
        form.append('profile', profile)
        form.append('introText', description)
        form.append('klaytnAddress', klaytnAddress)
        form.append('cvText', JSON.stringify({"cv": cv}))
        try {
            if (id) {
                const res = await api.updateArtist(user,id,form)
                console.log('result: ' + JSON.stringify(res))
            } else {
                const res = await api.addArtist(user,form)
                console.log('result: ' + JSON.stringify(res))
            }
            setLoading(false)
            navigate('/artist')
        } catch (e) {
            console.log('Error: ' + e)
            setLoading(false)
        }
    }

    const inputContentFontSize = {style: {fontSize: 12}}

    return <Loader active={loading}>
    <ThemeProvider theme={adminTheme}>
    <div className={css_container}>
    <h1>작가등록</h1>
    
    <FormGroup style={{width: '100%'}}>
        <form style={{width: '100%'}}>
        <Box height={50} />
        <h2>기본정보</h2>
        <hr />
        <h4>작가 이름(한글)</h4>
        <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setAlias(e.target.value)} value={alias}></TextField>
        <h4>작가 이름(영어)</h4>
        <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setAliasEn(e.target.value)} value={aliasEn}></TextField>
        <h4>생년</h4>
        <TextField value={birth} required id="outlined-required" label="Optional" fullWidth onInput={ e => setBirth(e.target.value)}></TextField>
        <h4>인스타그램</h4>
        <TextField value={instagram} required id="outlined-required" label="Optional" fullWidth onInput={ e => setInstagram(e.target.value)}></TextField>
        <h4>Klaytn 주소</h4>
        <TextField value={klaytnAddress} required id="outlined-required" label="Optional" fullWidth onInput={ e => setKlaytnAddress(e.target.value)}></TextField>
        <h4>작가 설명</h4>
        <TextField value={description} id="outlined" multiline rows={4} label="Optional" fullWidth onInput={ e => setDescription(e.target.value)}></TextField>
        <h4>프로필 이미지</h4>
        <Button variant='contained' component='label' style={{marginTop: '40px'}}>Upload Profile Image <input type='file' hidden accept=".jpg, .png, .svg, .webp" onChange={onProfileUploaded} multiple={false}/> </Button>
        { profileBase64 ?
            <>
                <br />
                <Button onClick={deleteProfile} variant='contained' component='label' style={{marginTop: '45px', width: '60px', height: '25px'}}>삭제</Button>
                <img src={profileBase64} style={{padding: '10px', maxWidth: '100%'}}/>
            </>
            : null
        }
        <h2>작가 이력</h2>
        <hr />

        <Box height={15} />
        <Button onClick={() => {onAddCV(0)}} variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon />밑에 작가 이력 추가</Button>
        {
            cv.map( (cvItem, index) => {
                return <div key={index}>
                    <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}><h5>Title</h5>
                    <Button onClick={()=>{deleteCV(cvItem)}} color="secondary" style={{verticalAlign: "center", width: '10px', height: '30px'}}><DeleteOutlined /></Button>
                    </div>
                    <TextField defaultValue={cvItem.title} required id="outlined-required" label="Optional" fullWidth onInput={ e => { cvItem.title = e.target.value; forceUpdate()}}></TextField>
                    <h5>Contents</h5>
                    <TableContainer>
                    <Table sx={{minWidth: 650}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Text</TableCell>
                                <TableCell>Link</TableCell>
                                <TableCell>Del</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                    { cvItem.contents.map((content,contentIndex) => {
                        return <TableRow key={contentIndex}>
                                <TableCell width='15%'><TextField inputProps={inputContentFontSize} required id="outlined" onInput={ e => { content.date = e.target.value }} defaultValue={content.date}></TextField></TableCell>
                                <TableCell><TextField  inputProps={inputContentFontSize} fullWidth required id="outlined" onInput={ e => { content.text = e.target.value }} defaultValue={content.text}></TextField></TableCell>
                                <TableCell width='20%'><TextField  inputProps={inputContentFontSize} required id="outlined" onInput={ e => { content.link = e.target.value }} defaultValue={content.link}></TextField></TableCell>
                                <TableCell width='5%'><Button onClick={()=>{deleteContent(index,content)}} color="secondary" style={{verticalAlign: "center", width: '10px', height: '30px'}}><DeleteOutlined /></Button></TableCell>
                            </TableRow>
                         })
                    }
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <div style={{display:'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <Button onClick={() => {
                            addContent(index)
                        }} variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon /> {cvItem.title} 추가</Button>
                    </div>
                <Button onClick={()=>{onAddCV(index+1)}} variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon /> 밑에 작가 이력 추가</Button>
                </div>
            })
        }
        </form>
        <Box height={50} />
        <Button onClick={handleSubmit} variant='contained' component='label' style={{marginTop: '45px', width: '100%', height: '55px'}}>{id ? 'Update' : 'Register'}</Button>
        <Box height={150} />
    </FormGroup>
    </div>
    </ThemeProvider>
    </Loader>
}