import axios from 'axios';
import * as React from 'react';
import { useContext, useState, useEffect } from "react";
import { AuthContext } from '../service/auth';
import Api from "../service/api";
import { Button, TextField, Modal, Box } from '@mui/material';

function useAuth() {
    return useContext(AuthContext);
}

const api = new Api();

export default function Opportunity() {
  const user = useAuth().user;
  const [opportunities, setOpportunities] = useState([]);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [editLinks, setEditLinks] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [tempLink, setTempLink] = useState("");
  const handleOpenModal = (opportunity) => {
    setSelectedOpportunity(opportunity);
    setTempLink(editLinks[opportunity.opid] || opportunity.link);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOpportunity(null);
  };

  useEffect(() => {
      const getOpportunities = async () => {
          try {
              const res = await api.getRKMOpportunities(user);
              if (res.data.code === 200) {
                  setOpportunities(res.data.data);
                  console.log("GET /opportunity/all:", res.data.data);
              } else {
                  console.error("failed", res.data.result);
              }
          } catch (e) {
              console.error("error", e);
          }
      };
      if (user) { getOpportunities(); }
  }, [user]);

  const eventUploadHandler = async (item) => {
    try {
      const res = await api.postRKMOpportunities(
            user,
            item.id,
            item.type,
            item.location,
            item.country,
            item.title,
            editLinks[`${item.id}`],
            item.deadline,
            Date.now()
        );
      console.log("POST /admin/opportunity:", res);
    } catch (error) {
        console.error("Failed:", error);
    }
  };


  const eventDeleteHandler = async (opportunity) => {
    try {
      console.log("opid:", opportunity.opid);
      const res = await api.delRKMOpportunities(user, opportunity.opid);
      console.log(`DELETE /admin/opportunity/${opportunity.opid}:`, res);
      
      setOpportunities(opportunities.filter(item => item.opid !== opportunity.opid));
    } catch (error) {
      console.error("Failed to delete opportunity:", error);
      setError(error.message);
    }
  };
  
    const eventEditHandler = async () => {
    if (!selectedOpportunity) return;

    console.log("수정할 opportunity:", selectedOpportunity);
  
    try {
      const res = await api.putRKMOpportunities(
        user,
        selectedOpportunity.opid,
        selectedOpportunity.originid,
        selectedOpportunity.type,
        selectedOpportunity.city,
        selectedOpportunity.country,
        selectedOpportunity.title,
        tempLink,
        selectedOpportunity.deadline,
        Date.now()
      );
      console.log("수정된 opportunity:", res);
      setEditLinks({ ...editLinks, [selectedOpportunity.opid]: tempLink });
      handleCloseModal();
    } catch (error) {
      console.error("Failed to update opportunity:", error.message);
      setError(error.message);
    }
  };

  const eventCrawlHandler = async () => {
    setIsLoading(true);
    setError(null);
    setParsedData([]);

    let page = 1;
    let allData = [];

    try {
      while (true) {
        try {
        const response = await axios.get('https://api.allorigins.win/get', {
          params: {
            url: `https://artdeadline.com/ops/page/${page}`
          }
        });

        const responseData = response.data.contents;

        const parser = new DOMParser();
        const doc = parser.parseFromString(responseData, 'text/html');

        const jobElements = doc.querySelectorAll('li.job');

        if (jobElements.length === 0) {
          break;
        }
        const extractedData = [];

        jobElements.forEach(jobElement => {
          const typeText = jobElement.querySelector('dd.type')?.textContent.trim() || '';
          const titleStrongText = jobElement.querySelector('dd.title strong')?.textContent.trim() || '';
          const linkElement = jobElement.querySelector('dd.title strong a');
          const linkText = linkElement.getAttribute('href'); 
          const titlePText = jobElement.querySelector('dd.title p b')?.textContent.trim() || '';
          const locationStrongText = jobElement.querySelector('dd.location strong')?.textContent.trim() || '';
          const locationSpanText = jobElement.querySelector('dd.location span')?.textContent.trim() || '';
          const idFromLink = linkText.replace("https://artdeadline.com/ops/", "")
          const dateString = titlePText.replace("International Deadline:", "").trim();
          const dateObject = new Date(dateString);
          const unixTimestamp = Math.floor(dateObject.getTime() / 1000);
          extractedData.push({
            type: typeText,
            title: titleStrongText,
            deadline: unixTimestamp,
            location: locationStrongText,
            country: locationSpanText,
            link: linkText,
            id: idFromLink
          });
        });

        allData = [...allData, ...extractedData];
        console.log("alldata", allData)
        page++;
        console.log("page", page)
        } catch(e) {
          console.log('While Exceiption: ' + e.toString())
          break
        }
      }
      setParsedData(allData);
    } catch (error) {
      setError('Error fetching data');
    } finally {
      setIsLoading(false);
    }
  };

  const uniqueOpportunities = opportunities.filter((obj, index, self) =>
    index === self.findIndex((t) => (
      t.title === obj.title
    ))
  );

  const uniqueData = parsedData.filter((obj, index, self) =>
    index === self.findIndex((t) => (
      t.id === obj.id
    ))
  ); 
  
  const uniqueOriginIds = opportunities.map(opportunity => opportunity.originid);
  const filteredUniqueData = uniqueData.filter(data => !uniqueOriginIds.includes(data.id));
  const handleTempLinkChange = (e) => {
    setTempLink(e.target.value);
  };
  console.log("op", opportunities)
  console.log("links", editLinks)
  console.log("filteredUniqueData",filteredUniqueData)

  return (
    <div>
      <div style={{backgroundColor:"skyblue", padding:"16px"}}>
        <h1>아트스푼에서 갖고 있는 데이터</h1>
        {opportunities.map((opportunity, index) => (
          <div key={index} style={{ marginBottom: '16px', backgroundColor: '#f9f9f9', border: 'solid 1px gray', borderRadius: '8px', padding: '16px' }}>
            <div>OPID: {opportunity.opid}</div>
            <div>Originid: {opportunity.originid}</div>
            <div>타입: {opportunity.type}</div>
            <div>도시: {opportunity.city}</div>
            <div>국가: {opportunity.country}</div>
            <div>제목: {opportunity.title}</div>
            <div>신청 링크: {editLinks[opportunity.opid] ? editLinks[opportunity.opid] : opportunity.link}</div> 
            <div>기한: {new Date(opportunity.deadLine * 1000).toLocaleString()}</div>
            <div>현재시각: {new Date(opportunity.timestamp).toLocaleString()}</div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '8px' }}
              onClick={() => handleOpenModal(opportunity)}
            >
              이벤트 수정하기
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => eventDeleteHandler(opportunity)}
            >
              이벤트 삭제하기
            </Button>
          </div>
        ))}

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box 
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              // border: '1px solid #000',
              borderRadius:"16px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2>이벤트 수정하기</h2>
            {selectedOpportunity && (
            <div>
              <TextField
                label="신청 링크: "
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ margin: '8px 0px' }}
                value={tempLink}
                  onChange={handleTempLinkChange}
              />
            </div>
            )}
            <Button variant="contained" color="primary" onClick={eventEditHandler}>
              저장
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCloseModal} style={{ marginLeft: '8px' }}>
              닫기
            </Button>
          </Box>
        </Modal>
      </div>

      <div style={{backgroundColor:"pink", padding:"16px"}}>
        <h1>React 웹 크롤러</h1>
        <button style={{cursor:"pointer", backgroundColor:"black", color:"white", padding: "8px", borderRadius:"6px"}} onClick={eventCrawlHandler} disabled={isLoading}>
          {isLoading ? '크롤링 되는 중 ⋰˚☆' : '크롤링 해오기 버튼ミ⛧'}
        </button> 
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {isLoading ? (
          <p>로딩중...</p>
        ) : (
          <div style={{ padding: '16px' }}>
            <h2 style={{ marginBottom: '16px' }}>데이터</h2>
            {filteredUniqueData.length > 0 ? 
              (<div>
                {filteredUniqueData.sort((a, b) => a.id.localeCompare(b.id)).map((item, index) => (
                  <div key={index} style={{ marginBottom: '16px', backgroundColor: '#f9f9f9', border: 'solid 1px gray', borderRadius: '8px', padding: '8px' }}>
                    <div>ID: {item.id}</div>
                    <div>타입: {item.type}</div>
                    게시글 링크: <a href={item.link} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                    <TextField
                      key={item.id}
                      label="신청 링크: "
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      style={{ margin: '8px 0px' }}
                      value={editLinks[item.id] || ''}
                      onChange={(e) => setEditLinks({ ...editLinks, [item.id]: e.target.value })}
                    />
                    <div>기한: {item.deadline ? item.deadline : "기한 없음"}</div>
                    <div>도시: {item.location}</div>
                    <div>국가: {item.country}</div>
                    <button style={{backgroundColor:"skyblue", padding: "8px", borderRadius:"6px", border:"1px solid white", cursor:"pointer"}}
                      onClick={() => eventUploadHandler(item)}>
                      업로드
                    </button>
                  </div>
                ))}
              </div>) : 
              (<p>추출된 데이터 없음</p>)}
          </div>
        )}
      </div>
    </div>
  );
}
