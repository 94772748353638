import React, { useEffect, useState } from "react";
import { Button, Link } from '@mui/material';
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import Api from '../service/api';
import { useContext } from "react";
import { AuthContext } from '../service/auth';

export default function Blog() {
    const [rows, setRows] = useState([]);
    const api = new Api();
    function useAuth() {
        return useContext(AuthContext);
    }

    const user = useAuth().user;
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'locale', headerName: 'Locale', width: 90 },
        { field: 'title', headerName: 'Title', width: 150, editable: true },
        { field: 'text', headerName: 'Text', width: 150, editable: true },
        { field: 'urlPath', headerName: 'URL', width: 150, editable: true },
        { field: 'imgUrl', headerName: 'Image', width: 150 },
        { field: 'category', headerName: 'Category', width: 150 },
        {
            field: 'created',
            headerName: 'Created',
            width: 150,
            renderCell: (params) => {
                const date = new Date(params.value);
                const formattedDate = date.toISOString().split('T')[0];
                return formattedDate;
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 220,
            renderCell: (params) => (
                <div>
                    <Link href={`/bloggetpost/${params.id}`} passHref>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: 8 }}
                            component="a" // Button을 a 태그로 렌더링하도록 설정
                        >
                            Go
                        </Button>
                    </Link>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'brown', color: 'white' }}
                        onClick={() => handleClick(params.id)}
                    >
                        Del
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        const fetchBlogList = async () => {
            try {
                const response = await api.getBlogList();
                const blogList = response.data.data.map(blog => ({
                    id: blog.blogid,
                    title: blog.title,
                    text: blog.text,
                    urlPath: blog.urlPath,
                    imgUrl: blog.imgUrl,
                    category: blog.category,
                    created: blog.created,
                    locale: blog.locale
                }));
                setRows(blogList);
                console.log("블로그 목록 데이터:", blogList);
            } catch (error) {
                console.error('Error fetching blog list:', error);
            }
        };

        fetchBlogList();
    }, []); 

    const handleClick = (blogid) => {
        if (window.confirm("정말로 삭제하시겠습니까?")) {
            handleDelClick(blogid);
        }
    };

    const handleDelClick = async (blogid) => {
        try {
            const res = await api.delBlogPost(user, blogid);
            console.log(`Blog post ${blogid} deleted successfully. Response:`, res)
            window.location.reload();
        } catch (error) {
            console.error(`Error deleting blog post ${blogid}:`, error); 
        }
    };
    

    return (
        <div style={{ height: "100vh" }}>
            <h1>Blog List</h1>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: "24px", marginBottom: "24px" }}>
                <Link href="/blognewpost" passHref>
                    <Button variant="contained" color="primary">
                        Add Blog
                    </Button>
                </Link>
            </div>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[10, 25, 50]}
                // checkboxSelection
                // disableSelectionOnClick
                getRowId={(row) => row.id}
                components={{
                    Toolbar: GridToolbarFilterButton,
                }}
                style={{ height: '100%', width: '100%' }}
                sortModel={[
                    {
                        field: 'created',
                        sort: 'desc',
                    },
                ]}
            />
        </div>
    );
}
