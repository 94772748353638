import * as React from 'react';
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';

import { css } from '@emotion/css'
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { Link, useNavigate } from 'react-router-dom';
import Api from '../service/api';

const css_container = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const css_header = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
`

const css_body = css`
    display: block;
    margin-top: 90px;
`

const css_copy_button = css`
    background: #fff;
    border: none;
    cursor: pointer;
`

const api = new Api()

export default function Art() {
    // 0: 판매대기, 1: 판매중, 2: 판매됨, 3: Private
    const states = ['Waiting', 'On Sale', 'Sold out', 'Private']
    const [data, setData] = React.useState([])
    const navigate = useNavigate()

    React.useEffect(async()=>{
        const response = await api.getArts()
        console.log('response.data: ' + JSON.stringify(response.data))
        const data = response.data
        const sortedData = data.sort((lhs,rhs) => lhs.id < rhs.id ? 1 : -1)
        setData(sortedData)
    },[])

    const onChangeStateClicked = (selectedArt) => {
        navigate('/art_put_on_sale', {state: {art: selectedArt}})
    }

    return (
        <ThemeProvider theme={adminTheme}>
        <div className={css_container}>
            <div className={css_header}>
                
                <h2 style={{display: "inline-block"}}> 작품 등록 페이지 </h2>
                <Link to="/art_add"><Button variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon /></Button></Link>
            </div>
        </div>
        <div className={css_body}>
            <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>작품</TableCell>
                                {/* <TableCell>정보</TableCell> */}
                                <TableCell>작가</TableCell>
                                {/* <TableCell>설명</TableCell> */}
                                {/* <TableCell>NFT</TableCell> */}
                                {/* <TableCell>갤러리</TableCell> */}
                                <TableCell>이미지</TableCell>
                                <TableCell>상태</TableCell>
                                <TableCell>편집</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            data.map((item, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{`${item.id}`}</TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    {/* <TableCell>{item.subtitle}</TableCell> */}
                                    <TableCell>{item.artist.alias}</TableCell>
                                    {/* <TableCell>{item.description}</TableCell> */}
                                    {/* <TableCell>{item.nft}</TableCell> */}
                                    {/* <TableCell>{'gallery'}</TableCell> */}
                                    <TableCell><img src={item.image} width={50}/></TableCell>
                                    {/* <TableCell>{`${item.onsale}`}</TableCell> */}
                                    <TableCell><Button onClick={() => onChangeStateClicked(item)}>{states[item.state]}</Button></TableCell>
                                    <TableCell><Link to={`/art_edit/${item.id}`}><Button variant="contained" color="secondary" style={{verticalAlign: "center", marginLeft: '50px'}}>편집</Button></Link></TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
            </TableContainer>
        </div>
        </ThemeProvider>
    );
}