import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from '../shared/App';
import Auth from '../service/auth';

const auth = new Auth();

const Root = () => (
    // <BrowserRouter>
    <App auth={auth}/>
    // </BrowserRouter>
);

export default Root;