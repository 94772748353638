import { createTheme } from '@mui/material/styles';

export const adminTheme = createTheme({
    palette: {
        primary: {
            main: '#2d44bb',
          },
        secondary: {
            main: '#222',
          },
    },
    typography: {
        allVariants: {
          color: '#222',
        },
      },
});