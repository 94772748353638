import { configureStore } from '@reduxjs/toolkit'
// import { getDefaultNormalizer } from '@testing-library/react'
import AppReducer from './app'

export const store = configureStore({
  reducer: {
      app: AppReducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({serializableCheck: false})],
  
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch