import { useContext, useState } from "react";
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';

import { css } from '@emotion/css'
import { Button, TextField } from '@mui/material';

import Api from '../service/api';
import { AuthContext } from '../service/auth';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from '../store/hook';
import Loader from './Loader';

const css_container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 90px 32px;
`

function useAuth() {
    return useContext(AuthContext);
}

const api = new Api()

export default function GalleryAdd() {

    const { galleries } = useAppSelector(state => state.app)
    const { id } = useParams()

    const user = useAuth().user;
    var g;
    if(id) {
        const gallery = galleries.filter((gallery) => { return gallery.id == id })
        if (gallery.length > 0) {
            g = gallery[0]
            
        }
    }

    const [name, setName] = useState(g ? g.name : "")
    const [contact, setContact] = useState(g ? g.contact : "")
    const [email, setEmail] = useState(g ? g.email : "")
    const [link, setLink] = useState(g ? g.link : "")
    const [address, setAddress] = useState(g ? g.address : "")
    const [klaytnAddress, setKlaytnAddress] = useState(g ? g.klaytnAddress : "")
    const [license, setLicense] = useState(g ? g.license : "")
    const [description, setDescription] = useState(g ? g.description : "")
    const [logo, setLogo] = useState(null)
    const [logoBase64, setLogoBase64] = useState(g ? g.logo : null)
    const [banner, setBanner] = useState(null)
    const [bannerBase64, setBannerBase64] = useState(g ? g.banner : null)
    const [seo, setSeo] = useState(g ? g.seo : "")
    const [loading, setLoading] = useState(false)

   

    const navigate = useNavigate()

    const handleSubmit = async() => {
        setLoading(false)

        const form = new FormData()
        form.append('name', name)
        form.append('description', description)
        form.append('contact', contact)
        form.append('email', email)
        form.append('license', license)
        form.append('link', link)
        form.append('address', address)
        form.append('klaytnAddress', klaytnAddress)
        form.append('logo', logo)
        form.append('banner', banner)
        form.append('seo', seo)
        
        if(id) {
            form.append('id', id)
            //put
            const res = await api.updateGallery(user, form)
            console.log('PUT res: ' + JSON.stringify(res))
            if (res.status == 200) {
                navigate('/gallery')
            } else {
                
            }
        } else {
            //post
            const res = await api.addGallery(user, form)
            console.log('POST res: ' + JSON.stringify(res))
            if (res.status == 200) {
                navigate('/gallery')
            } else {
                
            }
        }
        
        setLoading(true)
    }

    const onLogoUploaded = (e) => {
        const files = e.target.files
        if (files && files.length > 0 ) {
            const data = files[0]
            setLogo(data)
            new Promise((resolve,reject) => {
                    const reader = new FileReader()
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result)
                    })
                    reader.addEventListener('error', reject)
                    reader.readAsDataURL(data)
            })
            .then(base64 => {
                setLogoBase64(base64)
            }, error => {
                console.log(error)
            })
        }
    }

    const onBannerUploaded = (e) => {
        const files = e.target.files
        if (files && files.length > 0 ) {
            const data = files[0]
            setBanner(data)
            new Promise((resolve,reject) => {
                    const reader = new FileReader()
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result)
                    })
                    reader.addEventListener('error', reject)
                    reader.readAsDataURL(data)
            })
            .then(base64 => {
                setBannerBase64(base64)
            }, error => {
                console.log(error)
            })
        }
    }

    return (
        <Loader active={loading}>
        <ThemeProvider theme={adminTheme}>
        <div className={css_container}>
            <h1>갤러리 등록</h1>
            아직...수정기능이 없으니 신중히 등록하세요.
            <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <h4>갤러리 이름 (ex: Gallery Mark)</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setName(e.target.value)} defaultValue={name}></TextField>
                <h4>연락처 (010-1234-1234)</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setContact(e.target.value)} defaultValue={contact}></TextField>
                <h4>이메일</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setEmail(e.target.value)} defaultValue={email}></TextField>
                <h4>홈페이지 (https://artra.gallery)</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setLink(e.target.value)} defaultValue={link}></TextField>
                <h4>주소</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setAddress(e.target.value)} defaultValue={address}></TextField>
                <h4>클레이튼 주소</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setKlaytnAddress(e.target.value)} defaultValue={klaytnAddress}></TextField>
                <h4>사업자번호 (선택사항)</h4>
                <TextField id="outlined" label="Optional" fullWidth onInput={ e => setLicense(e.target.value)} defaultValue={license}></TextField>
                <h4>SEO (쉼표로 구분, 제일 처음은 한글이름.)</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setSeo(e.target.value)} defaultValue={seo}></TextField>
                <h4>소개글</h4>
                <TextField id="outlined" multiline rows={4} label="Optional" fullWidth onInput={ e => setDescription(e.target.value)} defaultValue={description}></TextField>
                <h4>로고</h4>
                <Button variant='contained' component='label' style={{marginTop: '40px'}}>Upload Logo Image <input type='file' hidden accept=".jpg, .png, .svg, .webp" onChange={onLogoUploaded} multiple={false}/> </Button>
                { logoBase64 ?
                    <img src={logoBase64} style={{padding: '10px', maxWidth: '100%'}}/> 
                    : null
                }

                <h4>배너 이미지</h4>
                <Button variant='contained' component='label' style={{marginTop: '40px'}}>Upload Banner Image <input type='file' hidden accept=".jpg, .png, .webp" onChange={onBannerUploaded} multiple={false}/> </Button>
                { bannerBase64 ?
                    <img src={bannerBase64} style={{padding: '10px', maxWidth: '100%'}}/> 
                    : null
                }
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '90px'}}>
                    <Button onClick={handleSubmit} style={{width: '300px', height: '64px', backgroundColor: '#2d44bb', color: '#fff', border:'none', fontWeight: 'bold'}}>등록하기</Button>
                </div>
               
            </form>
        </div>
        </ThemeProvider>
        </Loader>
    );
}