import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../service/api';
import { useContext } from "react";
import { AuthContext } from '../service/auth';
import { TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingFull from '../components/LoadingFull';

export default function BlogNewPost() {    
    const navigate = useNavigate();
    const api = new Api() 
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [urlPath, setUrlPath] = useState('');
    const [text, setText] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [category, setCategory] = useState('');
    const [locale, setLocale] = useState('en');
    const [loading, setLoading] = useState(false)

    const blogUploadHandler = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            const resData = await api.postBlog(user, title, urlPath, text, imgUrl, category, Date.now(), locale);
            console.log("response: ", resData)
            if (resData.code == 200) {
                console.log(`Upload Result:`, resData);
                enqueueSnackbar('업로드에 성공하였습니다.' , {variant: 'success'})
                navigate('/blog');
            } else {
                enqueueSnackbar('업로드에 실패하였습니다: ' + resData.result , {variant: 'error'})
            }
        } catch (error) {
            console.error('Upload Error:', error);
            enqueueSnackbar('업로드에 실패하였습니다.' , {variant: 'error'})
        }
        setLoading(false)
    };

    useEffect(() => {
        setUrlPath(title
            .toLowerCase() // 전부 소문자로 변경
            .replace(/[^\w\s가-힣ㄱ-ㅎㅏ-ㅣ]/g, '') // 문자, 숫자, 공백, 한글 자모음 제거
            .trim() // 앞뒤 공백 제거
            .replace(/\s+/g, '-') // 공백을 '-'로 대체
            .replace(/--+/g, '-') // 연속된 '-'를 하나로 줄임
            || '')
    }, [title]);

    useEffect(() => {
        const matchedImgUrl = text.match(/https:\/\/.*?\.(png|jpeg|jpg)/);
        setImgUrl(matchedImgUrl ? matchedImgUrl[0] : null);
    }, [text]);    

    return (
        <div>
            <LoadingFull open={loading} />
            <h1>새 게시물 작성하기</h1>
            <FormControl style={{width:"180px"}}>
                <InputLabel>Category</InputLabel>
                <Select
                    value={category}
                    label="Category"
                    onChange={(e) => setCategory(e.target.value)}
                >
                    <MenuItem value={"Featured"}>Featured</MenuItem>
                    <MenuItem value={"Tips"}>Tips</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{marginLeft:"16px", width:"180px"}}>
                <InputLabel>Locale</InputLabel>
                <Select
                    value={locale}
                    label="Locale"
                    onChange={(e) => setLocale(e.target.value)}
                >
                    <MenuItem value={"en"}>en</MenuItem>
                    <MenuItem value={"ko"}>ko</MenuItem>
                </Select>
            </FormControl>
            <TextField
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TextField
                label="Text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                minRows={4}
                maxRows={10} 
            />
            <TextField
                label="ImgUrl"
                value={imgUrl}
                fullWidth
                variant="outlined"
                margin="normal"
                disabled
            />
            <TextField
                label="UrlPath"
                value={urlPath}
                fullWidth
                variant="outlined"
                margin="normal"
                disabled
            />
            <Button 
                variant="contained"
                color="primary"
                onClick={blogUploadHandler}
            >
                제출
            </Button>
        </div>
    );
}
