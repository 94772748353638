import { useEffect } from "react"
import Api from "../service/api"
import { AuthContext } from '../service/auth';
import { useContext, useState } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { CopyAllOutlined, MailOutline } from "@mui/icons-material";
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

const api = new Api()

function useAuth() {
    return useContext(AuthContext);
}

export default function Soldout() {

    const [data, setData] = useState([])
    const user = useAuth().user;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const getSoldout = async() => {
            const res = await api.getSoldout(user)
            setData(res.data)
        }
        getSoldout()
    },[])

    const onCopyRecord = (record) => {
        const text = record.where_is_art == 0 ? getAddressInfo(record) : getNoneAddressInfo(record)
        copy(text)
        enqueueSnackbar('복사됨: ' + record.art.id, {variant: 'success'})
    }

    const onCopyReferrer = (ref) => {
        if(ref) {
            copy(ref)
            enqueueSnackbar('복사됨: ' + ref, {variant: 'success'})
        } else {
            enqueueSnackbar('추천인이 잘못됨.', {variant: 'error'})
        }
        
    }

    const getEmailTitle = (record) => {
        return `[ARTRA] 작품 판매 후 배송 안내 - ${record.art.title}`
    }

    const getAddressInfo = (record) => {
        return `
[배송 정보]%0D%0A
작품을 구매하신 고객님은 ‘작품 배송’을 요청 하셨기 때문에 고객님과 배송 방식과 일자를 논의한 후 배송을 완료해주시면 됩니다.%0D%0A
고객 이름: ${record.address.name}%0D%0A
고객 연락처: ${record.address.contact}%0D%0A
배송지: ${record.address.address_1} ${record.address.address_2}%0D%0A
배송 메모: ${record.address.memo}%0D%0A
%0D%0A
[판매 정보]%0D%0A
작가 이름: ${record.art.artist.alias}%0D%0A
작품 이름: ${record.art.title}%0D%0A
가격: ${record.price_won}원 (${parseInt(record.price/1000000000000000000/110*100)} 클레이)%0D%0A
블록체인 기록: https://scope.klaytn.com/tx/${record.tx}%0D%0A
`
    }

    const getNoneAddressInfo = (record) => {
        console.log(record)
        return `
[배송 정보]%0D%0A
작품을 구매하신 고객님은 ‘갤러리 보관’을 요청하셨기 때문에 해당 작품은 아트라로 배송해주시면 됩니다.%0D%0A
아트라 주소: 서울특별시 마포구 토정로 121-1 1층 ARTRA%0D%0A
연락처: 010-6679-8244%0D%0A
%0D%0A
[판매 정보]%0D%0A
작가 이름: ${record.art.artist.alias}%0D%0A
작품 이름: ${record.art.title}%0D%0A
가격: ${record.price_won}원 (${parseInt(record.price/1000000000000000000/110*100)} 클레이)%0D%0A
블록체인 기록: https://scope.klaytn.com/tx/${record.tx}%0D%0A
        `
    }

    const getEmailSubtitle = (record) => {
        return record.where_is_art == 0 ? `고객이 배송을 요청한 상태이니 다음 내용을 확인하여 고객님과 연락 후 작품 배송을 요청드립니다.%0D%0A%0D%0A` : `작품은 아트라로 배송해주시면 감사하겠습니다.%0D%0A%0D%0A`
    }

    const getEmailBody = (record) => {
        return `
안녕하세요. ${record.art.gallery.name} 담당자님.%0D%0A%0D%0A
아트라에 전시중이던 ${record.art.title} 작품이 ${record.price_won}원에 조금 전 판매 되었습니다.%0D%0A`+
`판매 직후 비용(클레이)은 클립 지갑으로 전송되었으며, 클레이를 현금화 하는 방법은 아래 링크에 자세히 안내드립니다.%0D%0A내용 확인 후 어려운 부분이 있으시면 언제든 아트라에 담당자에게 연락주세요.%0D%0A
https://artrasix.notion.site/Klay-4f890b247e874f9eaf63717190ba2216%0D%0A%0D%0A` +
(record.where_is_art == 0 ? getAddressInfo(record) : getNoneAddressInfo(record)) + `
%0D%0A%0D%0A작품 판매 축하드리며 문의사항은 언제든 아트라로 연락주세요.
%0D%0AARTRA%0D%0A
ARTRA 드림.
`
    }

    return (<>
        <h1>잘팔자!</h1>
        <Table>
            <TableHead>
                <TableCell>TX</TableCell>
                <TableCell>id</TableCell>
                <TableCell>작품</TableCell>
                <TableCell>이미지</TableCell>
                <TableCell>위치</TableCell>
                <TableCell>이름</TableCell>
                <TableCell>주소</TableCell>
                <TableCell>연락처</TableCell>
                <TableCell>가격(원)</TableCell>
                <TableCell>클레이(개)</TableCell>
                <TableCell>채굴</TableCell>
                <TableCell>복사</TableCell>
                <TableCell>메일</TableCell>
                <TableCell>추천인</TableCell>
            </TableHead>
            <TableBody>
                {
                    data.map((record, index) => {
                        return <TableRow key={index}>
                          
                          <TableCell><a href={`https://scope.klaytn.com/tx/${record.tx}`} target='_blank'>{(record.tx).substring(0,8)}</a></TableCell>
                          <TableCell><a href={`https://artra.gallery/detail/${record.art.id}`} target='_blank'>{record.art.id}</a></TableCell>
                            <TableCell>{record.art.title}</TableCell>
                            <TableCell><img src={record.art.image[0]} width={60}/></TableCell>
                            <TableCell>{record.where_is_art}</TableCell>
                            <TableCell>{record.address.name}</TableCell>
                            <TableCell>{record.address.address_1}<br/>{record.address.address_2}</TableCell>
                            <TableCell>{record.address.contact}</TableCell>
                            <TableCell>{record.price_won}</TableCell>
                            <TableCell>{record.price/1000000000000000000}</TableCell>
                            <TableCell>{record.minted_artra}</TableCell>
                            <TableCell><Button onClick={() => onCopyRecord(record)}><CopyAllOutlined /></Button></TableCell>
                            <TableCell><a href={`mailto:${record.art.gallery.email}?subject=${getEmailTitle(record)}&body=${getEmailBody(record)}`} target='_blank'><MailOutline /></a></TableCell>
                            <TableCell><Button onClick={() => onCopyReferrer(record.referrer)}>{(record.referrer ? record.referrer : "").substring(0,8)}</Button></TableCell>
                            
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
    
    </>)
}