import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './index'


export interface GalleryItemType {
  id: String;
  name: String;
  contact: String;
  email: String;
  klaytnAddress: String;
  address: String;
  description: String;
  link: String;
  license: String;
  logo: String;
  banner: String;
  seo: String;
}

export class GalleryItem implements GalleryItemType {
  constructor(public id: String, public name: String, public contact: String, public email: String, public klaytnAddress: String, public address: String, public description: String, public link: String, public license: String, public logo: String, public banner: String, public seo: String) {

  }
}

// "profile":"","uid":"1k3if6JaJXWy8FUbvBkzmNKUPoF3","alias":"Anonymous0","bg":"","liked":[""],"own":[""],"artist":false,"klaytn_address":"0xF1e038a942c3f264421525d42275Db7200F7A3a8"
export interface UserItemType {
  uid: String;
  alias: String;
  artist: boolean;
  klaytnAddress: String;
}

export class UserItem implements UserItemType {
  constructor(public uid: String, public alias: String, public artist: boolean, public klaytnAddress: String) {

  }
}

// slice의 타입 지정
export type AppType = {
  galleries: GalleryItemType[],
  users: UserItemType[],
}

// 초기값 지정
const initialState: AppType = {
  galleries: [],
  users: [],
}

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateGalleries: (state, action: PayloadAction<GalleryItemType[]>) => {
      state.galleries = action.payload
    },
    updateUsers: (state, action: PayloadAction<UserItemType[]>) => {
      state.users = action.payload
    },
  },
})

export const { updateGalleries, updateUsers } = AppSlice.actions

export const selectApp = (state: RootState) => state.app

export default AppSlice.reducer