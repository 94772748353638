import * as React from 'react';
import { useContext, useState } from "react";
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { css } from '@emotion/css'
import Api from '../service/api';
import { AuthContext } from '../service/auth';
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const api = new Api()

const css_container = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const css_header = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
`

const css_body = css`
    display: block;
    margin-top: 90px;
`

function useAuth() {
    return useContext(AuthContext);
}

export default function Artist() {
    const user = useAuth().user;

    const [data, setData] = useState([])

    React.useEffect(async()=>{
      const res = await api.getAllArtists()
      // if (res.code == 200) {
        const data = res.data
        const sorted = data.sort((lhs,rhs) => lhs.alias > rhs.alias ? 1 : -1)
        setData(sorted)
      // } else {
      //   console.log('ERROR: ' + JSON.stringify(res))
      // }
    },[])

    return (
        <ThemeProvider theme={adminTheme}>
        <div className={css_container}>
            
            <div className={css_header}>    
                <h2 style={{display: "inline-block"}}> 작가 등록 페이지 </h2>
                <Link to="/artist_add"><Button variant="contained" color="secondary" style={{verticalAlign: "center"}}><AddIcon /></Button></Link>
            </div>
        </div>
        <div className={css_body}>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}}>
            <TableHead>
                <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>작가</TableCell>
                    <TableCell>편집</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {
                data.map((item, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{`${item.id}`}</TableCell>
                        <TableCell>{item.alias} ({item.birth})</TableCell>
                        <TableCell><Link to={`/artist_edit/${item.id}`}><Button variant="contained" color="secondary" style={{verticalAlign: "center", marginLeft: '50px'}}>편집</Button></Link></TableCell>
                    </TableRow>
                ))
            }
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        </ThemeProvider>
    );
}