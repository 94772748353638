import * as React from 'react';
import { css } from '@emotion/css'

const css_container = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function Editor() {
    return <div></div>
}