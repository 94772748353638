import { Input, Button, InputAdornment, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import Api from "../service/api"
import { AuthContext } from "../service/auth"
import Loader from "./Loader"
import numberToKor from "../lib/numberToKor"

const api = new Api()

function useAuth() {
    return useContext(AuthContext);
}

export default function ArtPutOnSale(props) {
    const user = useAuth().user;
    const location = useLocation()
    const navigate = useNavigate()
    const art = location.state.art
    const [priceWon, setPriceWon] = useState(0)
    const [feeWon, setFeeWon] = useState(0)

    const [loading, setLoading] = useState(false)

    const handleSubmit = async() => {
        setLoading(true)
        const res = await api.setArtPrice(art.nft.contract, art.id, priceWon, feeWon)
        console.log('putOnSale res: ' + JSON.stringify(res))
        setLoading(false)
        if (res.status==200) {
            navigate('/art')
        } else {
            
        }
    }

    // useEffect(()=>{
    //     async function getKlay() {
    //         const klayPrice = await api.getKlayPrice()
    //         setKlayPrice(klayPrice)
    //     }
    //     getKlay()
    // },[]) 

    const onChangeWonAmount = (event) => {
        const value = event.target.value
        console.log(value)
        setPriceWon(parseInt(value))
        setFeeWon(parseInt(value / 10))
    }

    return (
    <Loader active={loading}>
    <h1>판매등록 페이지</h1>
    <div style={{display: 'flex'}}>
        <div style={{flex: '1'}}>
         <img src={art.image[0]} width='100%'/>
        </div>
        <div style={{width: '100%', paddingLeft: '25px', flex: '1'}}>
            <h1>{art.title}</h1>
            <h3 style={{color: '#666', marginBottom: '50px'}}>{art.artist.alias}</h3>
           
            
            <div>
                 <h2>가격 입력(원화, 수수료제외 판매가)</h2>
                <div><Input  fullWidth type="number" placeholder="10000000원" value={priceWon} onWheel={ event => event.target.blur()} onChange={onChangeWonAmount} endAdornment={<InputAdornment position="end">원</InputAdornment>}/></div>
                <p><b>{numberToKor(priceWon)}</b></p>
                <p>+ 수수료: <b>{feeWon}원</b></p>
                <p>= 총 원화: <b>{priceWon + feeWon}원</b></p>
                
                <Box height={50} />
     
            </div>
            
            <Button onClick={handleSubmit} variant='contained' component='label' style={{marginTop: '45px', width: '100%', height: '55px'}}>Ready for Sale</Button>
        </div>
    </div>
    </Loader>
    )
}