import React from "react";
import axios from "axios";
import BigInt from "big-integer";
import Hashids from "hashids";

const host = 'https://api-dev.artra.gallery:8881';
// const artspoonHost = 'https://api-rakama.artra.gallery:8884' //dev
const artspoonHost = 'https://api-rakama.artra.gallery:8882' //prod
// const artspoonHost = 'http://localhost:8882' //local
// const host = 'http://localhost:8881'
const origin = 'https://admin.artra.gallery';
// const origin = 'http://localhost:3000'

const ExchangeContract_BAOBAB = '0x6919f2f2d88af4a7fc89914dd3c8e289ced81381'
const ExchangeContract_CYPRESS = '0xcfea4a8b5232362a6d064fa8d3a30a5f476bce5d'
const ExchangeContract = ExchangeContract_CYPRESS

const ZeroAddress = '0x0000000000000000000000000000000000000000'
const hashids = new Hashids('0xF1e038a942c3f264421525d42275Db7200')

export default class Api {  
    async getHeader(user) {
        if (user != null) {
            const token = await user.getIdToken(true);
            return { 
                "uid": user.uid, 
                "Authorization": token,'version': '1.0',
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': origin 
            }
        } else {
            console.log(2)
            const timestamp =  Math.floor(Date.now() / 1000)
            const token = hashids.encode(timestamp)
            return { 
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': origin,
                "Auth": token,'version': '1.0'
            }
        }
    }
      
    async tryLogin(user) {
        const header = await this.getHeader(user);
        try {
            const response = await axios.get(host + '/api/admin/login', { headers: header });
            console.log(response.data);
            return response.data;
        } catch(error) {
            console.error(error);
        }
        
    }

    async getGallery() {
        const header = await this.getHeader();
        try {
            const response = await axios.get(host + '/api/gallery', {headers: header})
            return response.data
        } catch(error) {
            console.error(error);
        }
    }

    async addGallery(user, form) {
        const token = await user.getIdToken(true);
        const header = {
            "uid": user.uid, 
            "Authorization": token,
            'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }

        try {
            const res = await axios.post(host + '/api/admin/gallery', form, {headers: header})
            console.log('POST /api/admin/gallery: ' + JSON.stringify(res))
            return res
        } catch(error) {
            console.log(error)
        }
    }

        async addGallery(user, form) {
        const token = await user.getIdToken(true);
        const header = {
            "uid": user.uid, 
            "Authorization": token,
            'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }

        try {
            const res = await axios.post(host + '/api/admin/gallery', form, {headers: header})
            console.log('POST /api/admin/gallery: ' + JSON.stringify(res))
            return res
        } catch(error) {
            console.log(error)
        }
    }

    async updateGallery(user, form) {
        const token = await user.getIdToken(true);
        const header = {
            "uid": user.uid, 
            "Authorization": token,
            'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }

        try {
            const res = await axios.put(host + '/api/admin/gallery', form, {headers: header})
            console.log('PUT /api/admin/gallery: ' + JSON.stringify(res))
            return res
        } catch(error) {
            console.log(error)
        }
    }

    async getSoldout(user) {
        const header = await this.getHeader(user);
        try {
            console.log('[GET] /api/admin/soldout: ' + JSON.stringify(header))
            const response = await axios.post(host + '/api/admin/soldout',{}, { headers: header });
            console.log(response.data);
            return response.data;
        } catch(error) {
            console.error(error);
        }
    }

    async getArts() {
        const header = await this.getHeader();
        try {
            const res = await axios.get(host + '/api/art', {headers: header})
            return res.data
        } catch (error) {
            console.log(error)
        }
    }

    async getArt(id) {
        const header = await this.getHeader();
        try {
            const res = await axios.get(host + '/api/art/detail/'+id, {headers: header})
            return res.data
        } catch (error) {
            console.log(error)
        }
    }

    async addArt(user, form) {
        const token = await user.getIdToken(true);
        const header = {
            "uid": user.uid, 
            "Authorization": token,
            'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }
        try {
            const res = await axios.post(host + '/api/admin/art', form, {headers: header})
            
            return res.data
        } catch(error) {
            console.log(error)
        }
    }

    async updateArt(user, form) {
        const token = await user.getIdToken(true);
        const header = {
            "uid": user.uid, 
            "Authorization": token,
            'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }
        try {
            const res = await axios.put(host + '/api/admin/art', form, {headers: header})
            console.log('[PUT] /api/admin/art: ' + JSON.stringify(res))
            return res.data
        } catch(error) {
            console.log(error)
        }
    }

    // {"result":"success","code":"200","data":[{"profile":"","uid":"1k3if6JaJXWy8FUbvBkzmNKUPoF3","alias":"Anonymous0","bg":"","liked":[""],"own":[""],"artist":false,"klaytn_address":"0xF1e038a942c3f264421525d42275Db7200F7A3a8"}]}
    async getUsers(user) {
        const header = await this.getHeader(user);
        console.log('uid: ' + user.uid)
        try {
            const res = await axios.get(host + '/api/admin/users', {headers: header})
            return res
        } catch (error) {
            console.log(error)
        }
    }

    async getBizUsers() {
        const header = await this.getHeader();
        try {
            const res = await axios.get(host + '/api/biz/users/1', {headers: header})
            return res.data.data
        } catch (error) {
            console.log(error)
        }
    }


    async setArtPrice(nftContract,id,priceWon,feeWon) {
        const header = await this.getHeader()
        const body = {
            'exchangeContract': ExchangeContract,
            'nftContract': nftContract,
            'tokenId': parseInt(id),
            'priceWon': priceWon,
            'feeWon': feeWon
        }
        console.log('body: ' + JSON.stringify(body) ) 
        try {
            const res = await axios.post(host + '/api/art/sale', body, {headers: header})
            return res
        } catch (error) {
            console.log(error)
        }
    }

    async getCoinPrice(code) {
        // const res = axios.get(`https://api.coinone.co.kr/ticker?currency=${code}`)
        const res = await axios.get(`https://api.bithumb.com/public/ticker/${code}`)
        console.log(res.data.data)
        return res.data.data
    }

    async getKlayPrice() {
        const res = await this.getCoinPrice('klay')
        const price = res.closing_price
        const lastPrice = parseFloat(price)
        console.log(lastPrice)
        return lastPrice
    }

    async addArtist(user, form) {
        const token = await user.getIdToken(true);
        const header = { 
            "uid": user.uid, 
            "Authorization": token,'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }
       
        try {
            const res = await axios.post(host + '/api/admin/artist', form, {headers: header})
            console.log('[POST] /api/admin/artist: ' + JSON.stringify(res))
            return res.data
        } catch(error) {
            console.log(error)
        }
    }

    async updateArtist(user, id, form) {
        const token = await user.getIdToken(true);
        const header = { 
            "uid": user.uid, 
            "Authorization": token,'version': '1.0',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': origin 
        }
       
        try {
            const res = await axios.put(host + '/api/admin/artist/' + id, form, {headers: header})
            console.log('[PUT] /api/admin/artist/'+ id + ': ' + JSON.stringify(res))
            return res.data
        } catch(error) {
            console.log(error)
        }
    }

    async getArtist(artistId) {
        const header = await this.getHeader();
        try {
            const res = await axios.get(host + '/api/artist/'+artistId, {headers: header})
            return res.data
        } catch (error) {
            console.log(error)
        }
    }

    async getAllArtists() {
        const header = await this.getHeader();
        try {
            const res = await axios.get(host + '/api/artists', {headers: header})
            return res.data
        } catch (error) {
            console.log(error)
        }
    }

    // {
    //     "uid": "abcdefg", //아빠꺼
    //     "child": "b23424", //본인꺼
    //     "klaytnAddress": "0x123123",
    //     "timestamp": 1667492034,
    //     "grade": 2,
    //     "amount": 3300000,
    //     "fee": 990000,
    //     "paid": 0,
    //     "artra": 3000,
    // }
    async updateUserMembership(user, childUid, parentKlaytnAddress, grade, salesAmount, feeAmount, artraAmount) {
        const header = await this.getHeader(user);
        const body = {
            "uid": "parent",
            "child": childUid,
            "klaytnAddress": parentKlaytnAddress,
            "timestamp": Math.floor(Date.now() / 1000),
            "grade": grade,
            "amount": salesAmount,
            "fee": feeAmount,
            "paid": 0,
            "artra": artraAmount
        }
        console.log(JSON.stringify(body))
        try {
            const response = await axios.post(host + '/api/admin/biz/sale', body, { headers: header });
            console.log(response.data);
            return response.data;
        } catch(error) {
            console.error(error);
        }
    }

    async getAllSalesRecords(user) {
        const header = await this.getHeader(user)
        try {
            const response = await axios.get(host + '/api/admin/biz/sales', { headers: header });
            console.log(response.data);
            return response.data;
        } catch(error) {
            console.error(error);
        }
    }

    async addPoints(user, uid, point, tx) {
        const header = await this.getHeader(user)
        const timestamp =  Math.floor(Date.now() / 1000)
        const body = {
            "uid": uid,
            "point": point,
            "tx": tx,
            "timestamp": timestamp
        }
        console.log(JSON.stringify(body))
        try {
            const res = await axios.post(host + '/api/biz/addPoints', body, {headers: header})
            console.log(JSON.stringify(res))
            return res.data
        } catch(error) {
            console.error(error)
        }
    }


    //RAKAMA
    async getRKMHeaders(user) {
        if(user != null) {
            console.log(1)
            const token = await user.getIdToken(true);
            console.log(token)
            return {
                'uid': user.uid,
                'Content-type': 'application/json',
                'Cache-Control': 'public, max-age=31536000, immutable',
                'Authorization': token
            }
        } else{
            console.log(2)
            const RKMhashids = new Hashids('com.nakama.webapp', 5, "23456789ABCDEFGHJKLMNPQRSTUVWXZ")
            return {
                'Content-type': 'application/json',
                'Cache-Control': 'public, max-age=31536000, immutable',
                'Authorization': RKMhashids.encode(Date.now())
            }
        }
        
        
        
    }

    async getRKMAll(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        // const host = 'http://localhost:8882'
        const res = await axios.get(host+'/admin/all', {headers: header})
        return res
    }

    async delRKMUser(user,uid) {
        const header = await this.getRKMHeaders(user)
        header['uid'] = uid
        const host = artspoonHost
        // const host = 'http://localhost:8882'
        const res = await axios.delete(host+'/admin/user', {headers: header})
        return res
    }

    async getRKMUsers(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        // const host = 'http://localhost:8882'
        const res = await axios.get(host+'/admin/users', {headers: header})
        return res
    }

    async getRKMArts(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        // const host = 'http://localhost:8882'
        const res = await axios.get(host+'/admin/artworks', {headers: header})
        return res
    }

    async getRKMInventories(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        // const host = 'http://localhost:8882'
        const res = await axios.get(host+'/admin/inventories', {headers: header})
        return res
    }

    async getRKMCurrentPlan(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        const res = await axios.get(host+'/admin/plan/current', {headers: header})
        return res
    }

    async getRKMHistories(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        const res = await axios.get(host+'/admin/plan/histories', {headers: header})
        return res
    }

    // Opportunity
    async getRKMOpportunities(user) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        const res = await axios.get(host+'/opportunity/all', {headers: header})
        return res
    }
    
    async postRKMOpportunities(user, originid, type, city, country, title, link, deadLine, timestamp) {
        const header = await this.getRKMHeaders(user);
        const body = {
            "originid": originid,
            "type": type,
            "city": city,
            "country": country,
            "title": title,
            "link": link,
            "deadLine": deadLine,
            "timestamp": Date.now(),
        };
        console.log("body", body);
        try {
            const res = await axios.post(artspoonHost + '/admin/opportunity', body, { headers: header });
            console.log("res",res);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }
    async delRKMOpportunities(user, opid) {
        const header = await this.getRKMHeaders(user)
        const host = artspoonHost
        // const host = 'http://localhost:8882'
        const res = await axios.delete(host+`/admin/opportunity/${opid}`, {headers: header})
        return res
    }

    async putRKMOpportunities(user, opid, originid, type, city, country, title, link, deadLine, timestamp) {
        const headers = await this.getRKMHeaders(user)
        const host = artspoonHost
        const body = {
          "opid": opid,
          "originid": originid,
          "type": type,
          'city': city,
          "country": country,
          "title": title, 
          'link': link,
          'deadLine': deadLine,
          "timestamp": timestamp
        }
        try {
          const res = await axios.put(host + `/admin/opportunity`, body, { headers });
          return res.data;
        } catch (error) {
          console.error("Error:", error.response ? error.response.data : error.message);
          throw error;
        }
      }
      
      //Blog
        async postBlog(user, title, urlPath, text, imgUrl, category, created, locale) {
            const header = await this.getRKMHeaders(user); 
            const body = {
                "title": title,
                "urlPath": urlPath,
                "text": text,
                "imgUrl": imgUrl,
                "category": category,
                "created": created,
                "locale": locale
            };
            try {
                const res = await axios.post(artspoonHost + '/admin/blog/post', body, { headers: header });
                console.log("res", res);
                return res.data;
            } catch (error) {
                console.error("Request Error:", error);
                if (error.response) {
                    console.error("Response Error Data:", error.response.data);
                }
                throw error; 
            }
        }

        async getBlogList(user, locale) {
            const header = await this.getRKMHeaders(user)
            header['locale'] = locale
            const host = artspoonHost
            const res = await axios.get(host+'/blog/list', {headers: header})
            return res
        }

        async delBlogPost(user, blogid) {
            try {
                const header = await this.getRKMHeaders(user);
                const host = artspoonHost;
                const res = await axios.delete(`${host}/admin/blog/post/${blogid}`, { headers: header });
                return res;
            } catch (error) {
                console.error('Error deleting blog post:', error);
                throw error; 
            }
        }

        async getBlogPost(user, blogid, locale) {
            try {
                const header = await this.getRKMHeaders(user);
                header['locale'] = locale
                const host = artspoonHost;
                const res = await axios.get(`${host}/blog/post/${blogid}`, { headers: header });
                return res;
            } catch (error) {
                console.error('Error fetching blog post:', error);
                throw error; 
            }
        }
        
        async putBlogPost(user, id, title, urlPath, text, imgUrl, category, created, locale) {
            const headers = await this.getRKMHeaders(user);
            const host = artspoonHost;
            const body = {
                "blogid": id,
                "title": title,
                "urlPath": urlPath,
                "text": text,
                "imgUrl": imgUrl,
                "category": category,
                "created": created,
                "locale": locale
            };
            try {
                const res = await axios.put(host + `/admin/blog/post`, body, { headers: headers });
                return res.data;
            } catch (error) {
                console.error("Error:", error.response ? error.response.data : error.message);
                throw error;
            }
        }

        async getPaymentHistories(user) {
            const header = await this.getRKMHeaders(user)
            try {
                const res = await axios.get(artspoonHost + `/admin/payment/history`, { headers: header })
                return res
            } catch (error) {
                console.error("Error:", error.response ? error.response.data : error.message);
                throw error;
            }
        }
}