import * as React from 'react';
import {firebaseAuth, googleProvider} from './firebase'; 

class Auth {
    login(name) {
        const provider = this.getProvider(name);
        return firebaseAuth.signInWithPopup(provider);
    }
    
    logout() {
        firebaseAuth.signOut();
    }

    onAuthChange = (callback) => {
        firebaseAuth.onAuthStateChanged( user => {
            callback(user);
        })
    }
    
    getProvider(name){
        switch(name){
          case 'Google':
            return googleProvider;
          default:
            throw new Error(`${name} is unknown provider.`);
        }
    }
};

const firebaseAuthProvider = {
    getUser() {
        console.log("firebaseAuth.currentUser: " + firebaseAuth.currentUser);
        return firebaseAuth.currentUser;
    },
    signin(address, callback) {
        console.log(address);
        firebaseAuth.signInWithPopup(googleProvider)
            .then((result) => {
                callback(result.user);
            });
    },
    signout(callback) {
        firebaseAuth.signOut();
        callback();
    }
}


const AuthContext = React.createContext(null);
  
export { firebaseAuthProvider, AuthContext };

export default Auth;

