import * as React from 'react';
import { Autocomplete, Box, Button, Input, FormGroup, InputAdornment, MenuItem, Select, Stack, TextField, Checkbox, Radio } from "@mui/material";
import { css } from '@emotion/css'
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { useState } from "react";
import { AuthContext } from '../service/auth';
import { useNavigate, useParams } from 'react-router';
import Api from "../service/api";
import { GalleryItem, updateGalleries, updateUsers, UserItem } from '../store/app';
import { useAppDispatch, useAppSelector } from '../store/hook';
import Loader from './Loader';

const css_container = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 90px 32px;
`

const css_img_container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const css_img_header = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

function useAuth() {
    return React.useContext(AuthContext);
}

const api = new Api()

export default function ArtAdd() {
    
    const [art, setArt] = useState(null)
    const { id } = useParams()
    console.log(id)

 
    const artCategories = ['Painting', 'Drawing', 'Print', 'Photography', 'Sculpture', 'Work on Paper', 'Mixed art', 'Etc']
    const royaltyTypes = [
        {id: 0, title: 'Artist 0%, Gallery 100%'},
        {id: 1, title: 'Artist 10%, Gallery 90%'},
        {id: 2, title: 'Artist 20%, Gallery 80%'},
        {id: 3, title: 'Artist 30%, Gallery 70%'},
        {id: 4, title: 'Artist 40%, Gallery 60%'},
        {id: 5, title: 'Artist 50%, Gallery 50%'},
        {id: 6, title: 'Artist 60%, Gallery 40%'},
        {id: 7, title: 'Artist 70%, Gallery 30%'},
        {id: 8, title: 'Artist 80%, Gallery 20%'},
        {id: 9, title: 'Artist 90%, Gallery 10%'},
        {id: 10, title: 'Artist 100%, Gallery 0%'},
    ]
    
    const navigate = useNavigate()
    const user = useAuth().user
    const [images, setImages] = useState([])
    const [imagesBase64, setImagesBase64] = useState([])
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState('')
    const [artist, setArtist] = useState('')
    const [artistName, setArtistName] = useState('')
    const [artistDescription, setArtistDescription] = useState('')
    const [gallery, setGallery] = useState('')
    const [created, setCreated] = useState('')
    const [category, setCategory] = useState(artCategories[0])
    const [royaltyType, setRoyaltyType] = useState(0)

    const [description, setDescription] = useState('')

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [tall, setTall] = useState(0)
    const [hide, setHide] = useState(0)
    const [tags, setTags] = useState('')
    
    const [loading, setLoading] = useState(false)
    const { galleries } = useAppSelector(state => state.app)
    const [artists, setArtists] = useState([])
    const [artistIndex, setArtistIndex] = useState(null)
    const [collectionIndex, setCollectionIndex] = useState(0)
    
    const dispatch = useAppDispatch()
    
  
    React.useEffect(async () => {

        const artistsRes = await api.getAllArtists()
        if(artistsRes.code == 200) {
            setArtists(artistsRes.data)
        }

        const response = await api.getGallery()
        // console.log(`gallery response: ${JSON.stringify(response)}`)
        const galleries = response.map((item) => {
            return new GalleryItem(item['id'], item['name'], item['contact'], item['email'], '0x1234', item['address'])
        })
        dispatch(updateGalleries(galleries))

        if (id == null) return
        const res = await api.getArt(id)
        if (res.data) {
            const art = res.data
            
            setTitle(art.title)
            setSubtitle(art.subtitle)
            // setArtist(art.artist) //FIXME: 위험할것같음 서버로직 확인.
            setArtistName(art.artist['alias'])
            setArtistDescription(art.artist['description'])
            setGallery(art.gallery['id'])
            setCreated(art.created)
            setCategory(art.category)
            setRoyaltyType(art.royalty_type)
            setDescription(art.description)
            setWidth(art.width)
            setHeight(art.height)
            setTall(art.tall)
            setHide(art.hide)
            setTags(art.tags)
            setImagesBase64(art.image.map((image,index)=>{return {id: index, blob: image}}))
            setArt(art)
            setArtistIndex(art.artistIndex)
            setCollectionIndex(art.collectionIndex)

            
        } else {
            console.log('/api/detail/art/'+id+' failed')
        }
    },[])
    
    const onImageUploaded = (event) => {
        readUri(event)
    }

    const readUri = (e) => {
        if (e.target.files) {
            console.log('Start Prase!')
            const files = Array.from(e.target.files).map((file, index) => {
                return {id: index, file: file}
            })
            setImages([...images, ...files])
            Promise.all(files.map( data => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader()
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result)
                    })
                    reader.addEventListener('error', reject)
                    reader.readAsDataURL(data.file)
                }))
            }))
            .then(base64s => {
                const blobWrapper = base64s.map((blob, index) => {
                    return {id: index, blob: blob}
                })
                setImagesBase64([...imagesBase64, ...blobWrapper])
            }, error => {
                console.log(error)
            })
        }
    }
    const handleSubmit = async() => {
        if (artistIndex == null) {
            console.log('작가를 선택해 주시오.')
            return 
        }
        setLoading(true)
        const form = new FormData()
        form.append('title', title)
        form.append('subtitle', subtitle)
        form.append('artist', artist)
        form.append('artistName', artistName)
        form.append('artistDescription', artistDescription)
        form.append('gallery', gallery)
        form.append('created', created)
        form.append('category', category)
        form.append('description', description)
        form.append('royaltyType', royaltyType)
        form.append('hide', hide)
        form.append('width', width)
        form.append('height', height)
        form.append('tall', tall)
        form.append('tags', tags)
        form.append('artistIndex', artistIndex)
        form.append('collectionIndex', collectionIndex)
        
        console.log(title)
        console.log(subtitle)
        console.log(artist)
        console.log(artistName)
        console.log(artistDescription)
        console.log(gallery)
        console.log(created)
        console.log(category)
        console.log(description)
        console.log(royaltyType)
        console.log(hide)
        console.log(width)
        console.log(height)
        console.log(tall)
        console.log(tags)
        console.log(artistIndex)
        console.log(collectionIndex)
        
        const maxImageCount = 6
        for(var i = 0; i < Math.min(images.length, maxImageCount) ; i++) {
            console.log(images[i].file)
            form.append(`image${i}` , images[i].file)
        }

        if (id) {
            form.append('id', id)
        }
        const res = id ? await api.updateArt(user,form) : await api.addArt(user, form)
        console.log('/api/admin/art/: ' + res)

        if (res.code == 200) {
            navigate('/art')
        } else {
            console.log("Failed with code: " + res.code)
        }
        setLoading(false)
    }

    const handleDelete = (index) => {
        setImages([])
        setImagesBase64([])
    }

    const handleArtistSelected = (event, json) => {
        const artistId = json['id']
        setArtistIndex(json.id)
        setArtistName(json.alias)
        setArtistDescription(`${json.aliasEn} (B.${json.birth})`)
    }

    const handleGallerySelected = (event, json) => {
        console.log(json['id'])
        const galleryId = json['id']
        setGallery(galleryId)
    }

    const handleCategorySelected = (event) => {
        console.log('category selcted: ' + event.target.value)
        setCategory(event.target.value)
    }

    const handleRoyaltyTypeSelected = (event) => {
        console.log('handleRoyaltyTypeSelected: ' + event.target.value)
        setRoyaltyType(event.target.value)
    }
    if(id && art == null) return <div>Loading...</div>
    const selectedArtist = art ? artists.filter((artist) => artist.id == art.artistIndex)[0] : ""
    const selectedGallery = art ? galleries.filter((gallery) => {return gallery.id == art.gallery.id})[0] : ""
    return (
    <Loader active={loading}>
    <ThemeProvider theme={adminTheme}>
    <div>
        <div className={css_container}>
        <h1>작품 등록</h1>
        
        <FormGroup style={{width: '100%'}}>
        <form onSubmit={handleSubmit} style={{width: '100%', maxWidth: '700px'}}>
            <Box height={50} />
            
            <h2>작품</h2>
                <hr />
                <div style={{display: 'flex'}}>
                <h4>노출</h4>
                <Checkbox defaultChecked={hide == 0} onChange={()=>setHide(hide == 0 ? 1 : 0)}/>
                </div>
                <h4>작품 이름</h4>
                <TextField  required id="outlined-required" label="Required" fullWidth onInput={ e => setTitle(e.target.value)} defaultValue={title}></TextField>
                <h4>작품 크기 (width x height x tall, 그림일 경우엔 tall 0)</h4>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                
                <Input type="number" helperText="width" value={width} onWheel={ event => event.target.blur()} onChange={e => setWidth(e.target.value)} endAdornment={<InputAdornment position="end">cm</InputAdornment>}/>
                x
                <Input type="number" helperText="height" value={height} onWheel={ event => event.target.blur()} onChange={e => setHeight(e.target.value)} endAdornment={<InputAdornment position="end">cm</InputAdornment>}/>
                x
                <Input type="number" helperText="tall" value={tall} onWheel={ event => event.target.blur()} onChange={e => setTall(e.target.value)} endAdornment={<InputAdornment position="end">cm</InputAdornment>}/>
                </div>
                <h4>작품 정보(재료)</h4>
                <p>(Oil on canvas)</p>
                <TextField required id="outlined-required" label="Required" fullWidth onInput={ e => setSubtitle(e.target.value)} defaultValue={subtitle} ></TextField>
                <h4>작품 연도</h4>
                <p>(ex: 2022)</p>
                <TextField defaultValue={created} required id="outlined-required" label="Optional" fullWidth onInput={ e => setCreated(e.target.value)}></TextField>
                <h4>카테고리</h4>
                <p>Painting: 유화,아크릴 | Drawing: 선으로 그림 | Print: 판화 |<br />Photography: 사진 | Sculpture: 조각 | Work on Paper: 수채화등 |</p>
                <Select
                id="select-category"
                value={category}
                onChange={handleCategorySelected}
                label="Category"
                >
                    {
                        artCategories.map((category) => (<MenuItem key={category} value={category}>{category}</MenuItem>))
                    }
                </Select>
                <h4>Tags (쉼표로 구분)</h4>
                <TextField defaultValue={tags} required id="outlined-required" label="Optional" fullWidth onInput={ e => setTags(e.target.value)}></TextField>
                <h4>작품 소개</h4>
                <TextField defaultValue={description} id="outlined" multiline rows={4} label="Optional" fullWidth onInput={ e => setDescription(e.target.value)}></TextField>
            <Box height={50} />
            <h2>작가</h2>
                <hr />
                <h4>작가 - 이름으로 검색</h4>
                <Stack>
                    <Autocomplete
                        freeSolo={true}
                        id='search-artist'
                        disableClearable={true}
                        options={artists}
                        getOptionLabel={(option) =>  `[${option.id}] ${option.alias} (b.${option.birth})`}
                        renderInput={ (params) => (
                            <TextField 
                                {...params}
                                label = "Search Input"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                        onChange={handleArtistSelected}
                        defaultValue={selectedArtist}
                    />
                </Stack>
                {/* { artist.length > 0 ? null : <>
                <h4>작가이름 - 작가가 가입 안한 경우 기입. ex) 정영주</h4>
                <TextField defaultValue={artistName} required id="outlined-required" label="Required" fullWidth onInput={ e => setArtistName(e.target.value)}></TextField>
                <h4>작가설명 - 작가가 가입 안한 경우 기입. ex) JOUNG, YOUNG JU (b.1970)</h4>
                <TextField  defaultValue={artistDescription} required id="outlined-required" label="Required" fullWidth onInput={ e => setArtistDescription(e.target.value)}></TextField>
                </>
                } */}
            <Box height={50} />
            <h2>갤러리</h2>
                <hr />
                <h4>갤러리 - 이름으로 검색</h4>
                <Stack>
                    <Autocomplete
                        freeSolo={true}
                        id='search-artist'
                        disableClearable={true}
                        options={galleries}
                        getOptionLabel={(option) => option.name}
                        renderInput={ (params) => (
                            <TextField 
                                {...params}
                                label = "Search Input"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                        onChange={handleGallerySelected}
                        defaultValue={selectedGallery}
                    />
                </Stack>
                <Box height={50} />
            <h2>분배</h2>
                <hr />
                <h4>분배비율 선택</h4>
                <Stack>
                <Select
                    id="select-royalty-type"
                    value={royaltyType}
                    label="분배 타입"
                    onChange={handleRoyaltyTypeSelected}
                >
                    {
                        royaltyTypes.map((type) => { 
                            return <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>
                            })
                    }
                </Select>
                </Stack>
                <Box height={50} />
            <h2>Collection</h2>
            <hr />
            <h4>컬렉션 인덱스 선택 ex) 프리미어아트 서울: 2</h4>
            <Input type="number" helperText="Collection (Default 0)" value={collectionIndex} onWheel={ event => event.target.blur()} onChange={e => setCollectionIndex(e.target.value)} />
            <Box height={50} />
            <h2>작품 이미지 업로드 (최대 6장)</h2>
            <hr />   
            <Button variant='contained' component='label' style={{marginTop: '40px'}}>Upload Image <input type='file' hidden accept=".jpg, .png" onChange={onImageUploaded} multiple={true}/> </Button>
            <div className={css_img_container}>
            {
                imagesBase64.map( (data, index) => {
                    return (
                    <div style={{marginTop: '25px', width: '100%'}} key={index}>
                        <hr />
                        <div className={css_img_header}>
                            <p>{images[index] ? images[index].file.name : imagesBase64.blob} </p><Button variant='contained' component='label' onClick={() => {handleDelete(index)}}>삭제</Button>
                        </div>
                        <img src={data.blob} style={{padding: '10px', maxWidth: '100%'}}/>
                    </div>
                    )
                })
            }
            <Button onClick={handleSubmit} variant='contained' component='label' style={{marginTop: '45px', width: '100%', height: '55px'}}>{id ? 'Update' : 'Register'}</Button>
             </div>
        </form>
        </FormGroup>
        </div>
    </div>
    </ThemeProvider>
    </Loader>
    )
}