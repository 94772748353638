import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { adminTheme } from '../components/theme';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from "react-router-dom";

import Nav from '../components/Nav';
import {Login, Main, Gallery, Art } from '.';
import { firebaseAuthProvider, AuthContext } from '../service/auth';
import Artist from './Artist';
import Story from './Stroy';
import Banner from './Banner';
import GalleryAdd from './GalleryAdd';
import { firebaseAuth } from '../service/firebase';
import ArtAdd from './ArtAdd';
import ArtPutOnSale from './ArtPutOnSale';
import Soldout from './Soldout';
import { SnackbarProvider } from 'notistack';
import ArtistAdd from './ArtistAdd';
import Membership from './Membership';
import MembershipAdd from './MembershipAdd';
import PointAdd from './PointAdd';
import Rakama from './Rakama';
import Blog from './Blog';
import Opportunity from './Opportunity';
import BlogNewPost from './BlogNewPost';
import BlogGetPost from './BlogGetPost';
import Payment from './Payment';

export default function App(props) {
  const auth = useAuth();

  return (
    <ThemeProvider theme={adminTheme}>
      <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        {/* <Container> */}
        <div style={{padding:'32px'}}>
          <CssBaseline />
          <BrowserRouter>
          {/* <div>
              <ul>
                  <li><NavLink to="/">홈</NavLink></li>
                  <li><NavLink to="/gallery">갤러리 등록</NavLink></li>
                  <li><NavLink to="/art">작품 등록</NavLink></li>
                  <li><NavLink to="/login">로그인</NavLink></li>
              </ul>
          </div> */}
          <Nav auth={props.auth}/>
          <Routes>
            <Route path="/login" element={ <Login />} />
            <Route path="/" element={ <RequireAuth><Main /></RequireAuth>} />
            <Route path="/art" element={<RequireAuth><Art /></RequireAuth>} />
            <Route path="/gallery" element={ <RequireAuth><Gallery /></RequireAuth>} />
            <Route path="/gallery_add" element={<RequireAuth><GalleryAdd /></RequireAuth>} />
            <Route path="/gallery_edit/:id" element={<RequireAuth><GalleryAdd /></RequireAuth>} />
            <Route path="/art_add" element={<RequireAuth><ArtAdd /></RequireAuth>} />
            <Route path="/art_edit/:id" element={<RequireAuth><ArtAdd /></RequireAuth>} />
            <Route path="/art_put_on_sale" element={<RequireAuth><ArtPutOnSale /></RequireAuth>} />
            <Route path="/artist" element={ <RequireAuth><Artist /></RequireAuth>} />
            <Route path="/artist_add" element={ <RequireAuth><ArtistAdd /></RequireAuth>} />
            <Route path="/artist_edit/:id" element={<RequireAuth><ArtistAdd /></RequireAuth>} />
            <Route path="/story" element={ <RequireAuth><Story /></RequireAuth>} />
            <Route path="/banner" element={ <RequireAuth><Banner /></RequireAuth>} />
            <Route path="/soldout" element={ <RequireAuth><Soldout /></RequireAuth>} />
            <Route path="/membership" element={<RequireAuth><Membership /></RequireAuth>} />
            <Route path="/membership_add" element={<RequireAuth><MembershipAdd /></RequireAuth>} />
            <Route path="/membership_update/:uuid/:ugrade" element={<RequireAuth><MembershipAdd /></RequireAuth>} />
            <Route path="/point" element={<RequireAuth><PointAdd /></RequireAuth>} />
            <Route path="/rakama" element={<RequireAuth><Rakama /></RequireAuth>} />
            <Route path="/blog" element={<RequireAuth><Blog /></RequireAuth>} />
            <Route path="/blognewpost" element={<RequireAuth><BlogNewPost /></RequireAuth>} />
            <Route path="/bloggetpost/:id" element={<RequireAuth><BlogGetPost /></RequireAuth>} />
            <Route path="/opportunity" element={<RequireAuth><Opportunity /></RequireAuth>} />
            <Route path="/payment" element={<RequireAuth><Payment /></RequireAuth>} />
          </Routes>
          </BrowserRouter>
        {/* </Container> */}
        </div>
      </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}



function AuthProvider({children}) {
  const currentUser = firebaseAuthProvider.getUser();
  console.log("currentUser: " + currentUser);
  const [user, setUser] = React.useState(currentUser);

  React.useEffect(() => {
    firebaseAuth.onAuthStateChanged(async(user) => {
      if(user) {
        console.log('onAuthStateChanged: ' + user.uid)
        setUser(user)
      } else {
        setUser(null)
      }
    })
  }, [])

  const signin = (address, callback) => {
    return firebaseAuthProvider.signin(address, (user) => {
      console.log('set user');
      setUser(user);
      callback(user);
    });
  }
  const signout = (callback) => {
    return firebaseAuthProvider.signout(()=> {
      setUser(null);
      callback();
    });
  }

  const authorize = (user) => {
    setUser(user);
  }

  const value = { user, signin, signout, authorize};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({children}) {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(`RequireAuth Start with ${children}`)
  
  if (!auth.user) {
    console.log('RequireAuth auth user')
    firebaseAuth.onAuthStateChanged(async(user) => {
      if(user) {
        console.log('onAuthStateChanged: ' + user.uid)
        console.log('skip')
        return children;
      }  else {
        console.log('Navigate to=/login')
        return <Navigate to="/login" state={{from: location}} replace />;
      }
    })
    return <Login />
  } else {
    console.log('RequireAuth: ');
    return children;  
  }
}