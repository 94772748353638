import * as React from 'react';
import { adminTheme } from '../components/theme';
import { ThemeProvider } from '@mui/material/styles';
import { css } from '@emotion/css'
import Load from './Load';

const css_container = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function Main() {

    return (
        <ThemeProvider theme={adminTheme}>
        <Load />
        <div className={css_container}>
            <h2> 홈 </h2>
        </div>
        </ThemeProvider>
    );
}