import { useContext, useEffect, useState } from "react";
import Api from "../service/api";
import { AuthContext } from "../service/auth";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from "@mui/material";

const api = new Api()

export default function Payment() {
    
    function useAuth() {
        return useContext(AuthContext);
    }
    const user = useAuth().user;
    const [histories, setHistories] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedRows, setSelectedRows] = useState([])
    const [filterStartDate, setFilterStartDate] = useState(null)
    const [filterEndDate, setFilterEndDate] = useState(null)
    const [menu, setMenu] = useState(0) //0: All, 1: Live, 2: Paid
    const usdToKrw = 1380.0

    const tbHeaders = [
        {field: 'id', headerName:'ID', width: '60'},
        {field: 'uid', headerName:'UID', width: '60'},
        {field: 'live', headerName:'Live', width: '60'},
        {field: 'planid', headerName:'Plan', width: '80'},
        {field: 'productid', headerName:'Product', width: '120'},
        
        {field: 'amount', headerName:'Amount', width: '80', type: 'number'},
        {field: 'currency', headerName:'KRW', width: '60'},
        {field: 'started', headerName:'Start', width: '100', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
        {field: 'expired', headerName:'End', width: '100', renderCell: (params)=>(
            <p>{(new Date(params.value)).toLocaleDateString()}</p>
         )},
        {field: 'cancelled', headerName:'Cancel', width: '100', renderCell: (params)=>(
            <p>{params.value > 0 ? (new Date(params.value)).toLocaleDateString() : ''}</p>
         )},
         {field: 'action', headerName:'State', width: '120'},
    ]
    // {"historyid":1,"uid":1,"planid":"apro","productid":"apro_default","nextproductid":"apro_default","action":"payment_fail","started":1712903931607,"expired":1713595131607,"cancelled":1714457100125,"amounttotal":0,"amountsupplied":0,"amountvat":0,"currency":"KRW","paymentmethod":"","cardcompany":"","cardnumber":"","receipturl":"","checkouturl":"","paymentid":"","live":false}
    async function loadPayments() {
        setLoading(true)
        const res = await api.getPaymentHistories(user)
        if(res.data && res.data.code == 200) {
            const his = res.data.data.sort((l,r) => {
                if(l.historyid < r.historyid) {
                    return 1
                } else if(l.historyid > r.historyid) {
                    return -1
                } else {
                    return 0
                }
            })
            setHistories(his)
            loadRows(his)
            setLoading(false)
        }
    }

    function loadRows(histories) {
        const rows = histories.map(dataToRow)
        setRows(rows)
    } 

    function dataToRow(history) {
        return {
            id: history.historyid,
            uid: history.uid,
            planid: history.planid,
            productid: history.productid,
            action: history.action,
            started: history.started,
            expired: history.expired,
            cancelled: history.cancelled,
            amount: history.amounttotal,
            currency: history.currency,
            live: history.live
        }
    }

    function onRowSelected(selected) {
        console.log('selected: ' + selected)
        setSelectedRows(selected)
    }

    function startDateChanged(newValue) {
        console.log('startDate: ' + newValue)
        setFilterStartDate(newValue)        
        filterWithDate(newValue, filterEndDate)
    }

    function endDateChanged(newValue) {
        
        console.log('endDate: ' + newValue)
        setFilterEndDate(newValue)
        filterWithDate(filterStartDate, newValue)
    }

    function filterDate(item,startDate, endDate) {
        if(startDate != null && endDate != null) {
            return item.started > startDate && item.started < endDate
        } else if(startDate != null) {
            return item.started > startDate
        } else {
            return true
        }
    }

    function filterMenu(menu) {
        setMenu(menu)
        filter()
    }

    async function filter() {
        const startDate = filterStartDate
        const endDate = filterEndDate
        filterWithDate(startDate, endDate)
    }

    async function filterWithDate(startDate, endDate) {
        const filtered = histories.filter((row) => {
            switch(menu) {
                case 1:
                    if(row.amounttotal > 0) {
                        return filterDate(row, startDate, endDate)
                    } else {
                        return false
                    }
                case 2:
                    if(row.live == true) {
                        return filterDate(row, startDate, endDate)
                    } else {
                        return false
                    }
                default:
                    return filterDate(row, startDate, endDate)
            }
        })
        loadRows(filtered)
    }

    function resetDate() {
        setFilterStartDate(null)
        setFilterEndDate(null)
        filterWithDate(null, null)
    }

    useEffect(()=>{
        if(user) {
            loadPayments()
        }
    },[user])

    function buildStatusHeader() {
        return <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                    <DatePicker label="Start Date"
                        onChange={(newValue) => startDateChanged(newValue)}
                    />
                    <DatePicker label="End Date"
                    // value={value}
                        onChange={(newValue) => endDateChanged(newValue)}
                    />
                    <Button variant="contained" onClick={e=>resetDate()}>Reset</Button>
                </div>
            </LocalizationProvider>
            
        </div>
    }
    function buildFilter() {
        return <div style={{display:'flex', alignItems:'center'}}>
            <Button style={{color: menu==0 ? '#000' : '#999'}} onClick={e=>filterMenu(0)}>All</Button> |
            <Button style={{color: menu==1 ? '#000' : '#999'}} onClick={e=>filterMenu(1)}>Paid</Button> |
            <Button style={{color: menu==2 ? '#000' : '#999'}} onClick={e=>filterMenu(2)}>Live</Button>
        </div>
    }

    function buildStatus() {
        const paidHistories = histories.filter((row) => row.amounttotal > 0)
            
        const firstPaid = {}
        const lastPaid = {}

        paidHistories.forEach((row) => {
            const { uid, started } = row;
            
            if (!firstPaid[uid] || firstPaid[uid].started > started) {
                firstPaid[uid] = row;
            }
            if (!lastPaid[uid] || lastPaid[uid].started <= started) {
                lastPaid[uid] = row;
            }
        })

        const firstPaidUsers = Object.values(firstPaid)

        const lastPaidUsers = Object.values(lastPaid)

        const newPaidUsers = firstPaidUsers.filter((row) => filterDate(row, filterStartDate, filterEndDate))
        const exitPaidUsers = lastPaidUsers.filter(row => row.action != "start")

    
        const total = paidHistories
        .filter((row) => filterDate(row, filterStartDate, filterEndDate))
        .reduce((total, row) => {
            const amount = row.currency == 'USD' ? row.amounttotal * usdToKrw : row.amounttotal
            return total + amount
        }, 0)
        
        const monthly = paidHistories
        .filter((row) => filterDate(row, filterStartDate, filterEndDate))
        .reduce((total, row) => {
            const amount = row.currency == 'USD' ? row.amounttotal * usdToKrw : row.amounttotal
            if(amount > 20000) {
                //연간
                return total + 0
            } else {
                return total + amount
            }
        }, 0)

        const yearly = paidHistories
        .filter((row) => filterDate(row, filterStartDate, filterEndDate))
        .reduce((total, row) => {
            const amount = row.currency == 'USD' ? row.amounttotal * usdToKrw : row.amounttotal
            if(amount > 20000) {
                //연간
                return total + amount
            } else {
                return total + 0
            }
        }, 0)

        return <div>
            <div style={{display:'flex', alignItems:'center'}}>
                <span><b>Total:</b> {total.toLocaleString()} 원</span> <div style={{margin:'0px 8px', color:'#999'}}> | </div>
                <span><b>연간결제(총액):</b> {yearly.toLocaleString()} 원</span> <div style={{margin:'0px 8px', color:'#999'}}> | </div>
                <span><b>월간결제(총액):</b> {monthly.toLocaleString()} 원</span> <div style={{margin:'0px 8px', color:'#999'}}> | </div>
                <span><b>유료사용자(수):</b> {firstPaidUsers.length} 명</span> <div style={{margin:'0px 8px', color:'#999'}}> | </div>
                <span><b>유료이탈자(수):</b> {exitPaidUsers.length} 명</span> <div style={{margin:'0px 8px', color:'#999'}}> | </div>
                <span><b>기간내 신규결제자(수):</b> {newPaidUsers.length} 명</span> <div style={{margin:'0px 8px', color:'#999'}}> | </div>
                <span><b>기간내 유료전환율:</b> {newPaidUsers.length} %</span> <div style={{margin:'0px 8px', color:'#999'}}></div>
            </div>
        </div>
    }

    return <div style={{height:'100vh'}}>
        <h1>Payment</h1>
        <h3>Filter</h3>
        {buildStatusHeader()}
        {buildFilter()}
        <h3>Status</h3>
        {buildStatus()}
        <h3>Report</h3>
        <DataGrid 
            loading={loading}
            columns={tbHeaders}
            rows={rows}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    }
                }
            }}
            checkboxSelection={true}
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(selected) => {onRowSelected(selected)}}
            isRowSelectable={params => true}
            slots={{
                toolbar: GridToolbarFilterButton
            }}
        />
    </div>
}