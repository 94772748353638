import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../service/api';
import { AuthContext } from '../service/auth';
import { useContext } from "react";
import TextField from '@mui/material/TextField';
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingFull from '../components/LoadingFull';

const BlogGetPost = () => {
    const { id } = useParams();
    const api = new Api();
    const { user } = useContext(AuthContext);
    const [blogData, setBlogData] = useState(null);
    const [locale, setLocale] = useState('');
    const [category, setCategory] = useState('');
    const [created, setCreated] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    const [urlPath, setUrlPath] = useState('');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchBlogPost = async () => {
            try {
                const res = await api.getBlogPost(user, id);
                const postData = res.data.data;
                console.log("블로그 데이터:", postData);
                setBlogData(postData);
                setCategory(postData.category);
                setCreated(postData.created);
                setImgUrl(postData.imgUrl);
                setText(postData.text);
                setTitle(postData.title);
                setUrlPath(postData.urlPath);
                setLocale(postData.locale)
            } catch (error) {
                console.error('Error fetching blog post:', error);
            }
        };

        fetchBlogPost();
    }, [id, user]);

    // Event handlers for editing fields
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleLocaleChange = (event) => {
        setLocale(event.target.value);
    };

    const handleCreatedChange = (event) => {
        setCreated(event.target.value);
    };

    const handleImgUrlChange = (event) => {
        setImgUrl(event.target.value);
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleUrlPathChange = (event) => {
        setUrlPath(event.target.value);
    };

    const postEditHandler = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            const updatedPostData = {
                title,
                urlPath,
                text,
                imgUrl,
                category,
                created,
                locale
            };
            const res = await api.putBlogPost(user, id, updatedPostData.title, updatedPostData.urlPath, updatedPostData.text, updatedPostData.imgUrl, updatedPostData.category, updatedPostData.created, updatedPostData.locale);
            console.log('수정된 데이터:', res);
        } catch (error) {
            console.error('데이터 수정에 실패했어요.', error);
        } finally {
            navigate('/blog');
        }
        setLoading(false)
    };

    useEffect(() => {
        setUrlPath(title
            .toLowerCase() // 전부 소문자로 변경
            .replace(/[^\w\s]/g, '') // 문자가 아닌 것 제거
            .trim() // 앞뒤 공백 제거
            .replace(/\s+/g, '-') // 공백을 '-'로 대체
            .replace(/--+/g, '-') // 연속된 '-'를 하나로 줄임
            || '')
    }, [title]);

    useEffect(() => {
        const matchedImgUrl = text.match(/https:\/\/.*?\.(png|jpeg|jpg)/);
        setImgUrl(matchedImgUrl ? matchedImgUrl[0] : null);
    }, [text]);

    return (
        <div>
            <LoadingFull open={loading} />
            <h1>블로그 게시물 수정하기</h1>
            <FormControl style={{width:"180px"}}>
                <InputLabel>Category</InputLabel>
                <Select
                    value={category}
                    label="Category"
                    onChange={handleCategoryChange}
                >
                    <MenuItem value={"Featured"}>Featured</MenuItem>
                    <MenuItem value={"Tips"}>Tips</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{marginLeft:"16px", width:"180px"}}>
                <InputLabel>Locale</InputLabel>
                <Select
                    value={locale}
                    label="Locale"
                    onChange={handleLocaleChange}
                >
                    <MenuItem value={"en"}>en</MenuItem>
                    <MenuItem value={"ko"}>ko</MenuItem>
                </Select>
            </FormControl>
            <TextField
                label="Title"
                value={title}
                onChange={handleTitleChange}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TextField
                label="Text"
                value={text}
                onChange={handleTextChange}
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                minRows={4}
                maxRows={10} 
            />
            <TextField
                label="ImgUrl"
                value={imgUrl}
                onChange={handleImgUrlChange}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TextField
                label="UrlPath"
                value={urlPath}
                onChange={handleUrlPathChange}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <TextField
                label="Created"
                value={created}
                onChange={handleCreatedChange}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <Link href='https://www.unixtimestamp.com/' target='_blank'><p style={{color:'#666'}}>Unix time stamp</p></Link>
            <Button
                variant="contained"
                color="primary"
                onClick={postEditHandler}
            >
                수정
            </Button>
        </div>
        
    );
};

export default BlogGetPost;
