import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from '../service/auth';
import { css } from '@emotion/css'
import { Button } from '@mui/material';
import { adminTheme } from '../components/theme';

import Api from '../service/api';

function useAuth() {
  return React.useContext(AuthContext);
}

const css_container = css`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const api = new Api();
export default function Login(props) {
  
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useAuth();

  const from = location.state?.from?.pathname || "/";


  const onLogin = (e) => {
    auth.signin("0x11", async(user) => {
      console.log("user: " + user.uid);
      try {
        const res = await api.tryLogin(user);
        if (res.result === "success") {
          auth.authorize(user);
          navigate(from, { replace: true });
        } else {
          console.log(res.code);
        }
      } catch (error) {
        console.log('Error in tryLogin: ' + error);
      }
    });
  }

  

  return (
    <ThemeProvider theme={adminTheme}>
      <Container>
        <CssBaseline />
        <div className={css_container}>
          <Button  variant="contained" color="secondary" onClick={onLogin}>Signin with Google</Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}