import { Backdrop, Box, LinearProgress, ThemeProvider, createTheme, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function LoadingFull(props) {
    const useProgress = props.useProgress == null ? false : true
    const current = props.current
    const max = props.max 
    const msg = props.msg
    const [progress, setProgress] = useState(30)
    
    const theme = createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#000',
          },
          secondary: {
            main: '#2d44bb',
          },
        },
      })

    useEffect(()=>{
        if(max > 0) {
            const value = current / max
            setProgress(value * 100)
        }
    },[current])

    function GradientCircularProgress() {
      return (
        <React.Fragment>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
      );
    }

    return (
        <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
        >
            <div >
                <div style={{padding:'64px', backgroundColor:'rgba(0,0,0,0.8)', borderRadius:'10px'}}>
                <GradientCircularProgress />
                </div>
                {msg ? <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, 
                      display:'flex', justifyContent:'center', alignItems:'center', color:'#fff', marginTop:'250px'}}>
                    {msg}
                </div>: null}
                {useProgress ? 
                <ThemeProvider theme={theme}>
                <Box sx={{maxWidth: '350px', width:'100%', padding:'0 16px', marginTop: '24px'}}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box></ThemeProvider> : null}
            </div>
            
        </Backdrop>
    )
}